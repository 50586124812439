import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  ohide: boolean = true;
  chide: boolean = true;
  nhide: boolean = true;

  user: User;

  matcher = new MyErrorStateMatcher();


  changePassForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    old_password: new FormControl('', Validators.required),
    new_password: new FormControl('', Validators.required),
    confirm_password: new FormControl('',),
  }, this.checkPasswords);
  decryptedUserDetails: any;

  constructor(private authService: AuthService,
    private spinner: NgxSpinnerService, private moreScreenService: MoreScreenService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>, private _snackBar: MatSnackBar, private endPointService: ApiEndpointsService) {
    this.user = this.authService.userValue;
    this.changePassForm.patchValue({
      email: this.user.email,
    });
  }

  ngOnInit(): void {
    this.decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
  }

  async onSubmit() {
    if (this.changePassForm.valid) {
      this.spinner.show();
      let formData = this.changePassForm.value;
      (await this.moreScreenService.changePass(formData)).subscribe((res) => {
        this.spinner.hide();
        this.openSnackBar(res.message, "");
        if (res.status) {
          if (res.data) {
            let response: any;
            this.endPointService.decodeAesToken(this.authService.userValue.uid ?? this.decryptedUserDetails.uid, res.data).then((data) => {
              response = data;
              console.log('changePassword Response:-');
              this.openSnackBar(res.message, "");
              console.log(JSON.parse(JSON.stringify(JSON.parse(response.replace(/'/g, '"').replace(/None/g, null).replace(/False/g, false).replace(/True/g, true)))));
              this.dialogRef.close('changedPassword');
            });
          } else {
            this.dialogRef.close('changedPassword');
          }
        } else {
          console.log(res);
        }
      }, error => {
        this.spinner.hide();
      })
    }
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.new_password.value;
    let confirmPass = group.controls.confirm_password.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, { duration: 3000 });
  }
}
