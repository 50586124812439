

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GroupsService } from 'src/app/modules/groups/services/groups.service';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { GroupDeleteModel } from 'src/app/models/groups.model';
import { JobsService } from '../../services/jobs.service';
import {  FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobsComponent implements OnInit {
  tabName: string = 'all-jobs';
  showCreateGroup: boolean = false;
  submittedJobForm = false;
  applyJobForm = false;

  // myGroupsList: any[] = [];
  recommendedJobsList: any[] = [];
  requestedGroupsList: any[] = [];
  currentUrl: string;
  defaultImage = './assets/img/nushiftconnect_logo.svg';
  selectedIndex: number;
  companiesList: any
  isChecked: boolean = false;
  ananymous = 'no';
  showJobPreview: boolean = false;
  jobDetails: any;
  jobType: any;
  showJobApplyPreview: boolean = false;
  applicationForm: FormGroup;
  uploadedResume: File | any;
  public createJobForm: FormGroup;
  constructor(
    private groupsService: GroupsService,
    private jobsService: JobsService,
    private authService: AuthService,
    private nav: NavbarService,
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private apiEndPointService: ApiEndpointsService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {

    this.getRecommendedJobs();
    this.createApplicationForm();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.cf && params.cf.toLowerCase() == 'j') {
        // Goes inside this condition if side navtype clicked is groups after login
        this.authService.isLoggedIn.next({
          showNavbar: true,
          showSideNav: true,
          cf:
            params.cf && params.cf.toLowerCase()
              ? params.cf.toLowerCase()
              : 'j',
        });
      }
    });
    this.nav.setfooterNotFixed();


    this.getCompaniesList();

    this.createJobForm = this._formBuilder.group({      // Creating Formgroup to Edit/Create Group
      job_title: ['', Validators.required],
      organization_name:['', Validators.required],
      job_location: ['', Validators.required],
      job_type: ['', Validators.required],
      job_description: ['', Validators.required],
      uid: this.authService.userValue.uid
    });
  }
  async getCompaniesList() { 
    
    console.log("this.authService.userValue",this.authService.userValue);
    this.authService.isSpecialComponentActive = true;

      // To get the companies List for the Job Company Field
    let payload = {
      'user_name': this.authService.userValue.mob,
       uid: this.authService.userValue.uid
    };
    (await this.jobsService.getCompaniesList(payload)).subscribe((res) => {
      this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, res.data).then((data: any) => {
        this.companiesList = JSON.parse(data.replace(/'/g, '"'));
        console.log(this.companiesList.organization_name);
      });
    })

  }


  checkboxChanged(event: any) {
    this.isChecked = event.checked;
    // You can perform additional actions here based on the checkbox state
    if (this.isChecked) {
      console.log('Checkbox is checked');
      this.ananymous = 'yes';
      // Perform some action when checkbox is checked
    } else {
      console.log('Checkbox is unchecked');
      this.ananymous = 'no';
      // Perform some action when checkbox is unchecked
    }
  }
  async createJob() {  

    this.submittedJobForm = true;
    if (this.createJobForm.invalid) {
      return;
    }
else
{
    console.log(this.createJobForm.value,'createJobForm');
    let payload_data = this.createJobForm.value;
    payload_data['organization_name'] = this.createJobForm.value.organization_name;
    payload_data['is_anonymous'] = this.ananymous;
    console.log(payload_data,'create a job payload');
    this.spinner.show();
    (await this.jobsService.postJob(payload_data)).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res);
      if (res.status) {
        this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, res.data).then((data) => {
          let response = data;
          console.log(response);
          this.createJobForm.reset();
          this.submittedJobForm = false;
          // this.onTabChange("manage");
        });
        this.openSnackBar(res.message, "");
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar(error, "");
    })
  }
  }
  uploadResume(files: FileList): void {
    // if (event.target.files.length > 0) {
      this.uploadedResume = files.item(0);

      console.log(this.uploadResume);
      this.applicationForm.patchValue({
        resume: files.item(0),
      });
    // }
  }


  createApplicationForm() {
    this.applicationForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
      resume: ['', Validators.required]
    })
  }
  submitJob(job: any) {

    this.applyJobForm = true;
    if (this.applicationForm.invalid) {
      return;
    }
else
{

    let job_apply_payload = {
      uid: this.authService.userValue.uid,
      jobID: this.jobDetails.job_id,
      email: this.applicationForm.get('emailAddress').value,
      mobile: this.applicationForm.get('phoneNumber').value,
      country_code: "91"
    }

    console.log(job_apply_payload,"Job apply payload");
    this.uploadedResume['resume'] = this.uploadedResume.name;
    this.jobsService.applyJob(job_apply_payload, this.uploadedResume).subscribe((result) => {
      console.log(result);
      if (result.status) {
        this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, result.data).then((data) => {
          let response = data;
          console.log(response);
          this.applyJobForm = false;
        });
        this.openSnackBar(result.message, "");
      }
    })
    console.log(this.applicationForm.value);
  }
}
 // applyForJob(jobDetails): void {
    // const dialogRef = this.dialog.open(ApplyJobDialogComponent, {
    //   data: {
    //     jobDetails: jobDetails,
    //     comingFrom: this.comingFrom
    //   },
    //   disableClose: true
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result.res.status) {
    //     console.log(result);
    //     if (result.res.comingFrom.toLowerCase() == '') {
    //       this.getJobs();
    //     } else {
    //       this.getSavedJobs(result.res.comingFrom.toLowerCase());
    //     }
    //   }
    // });
 // }
  
  async getRecommendedJobs() {
    // Method to get my groups
    const formData = {
      job_title: '',
      uid: this.authService.userValue.uid,
    };

    // this.spinner.show();
    (await this.jobsService.getRecommendedJobs(formData)).subscribe(
      (res: any) => {
        // this.spinner.hide();
        // this.myjobsList = [];
        let response;
        if (res.status) {
          this.spinner.hide();
          console.log(res);
          this.apiEndPointService
            .decodeAesToken(this.authService.userValue.uid, res.data)
            .then((data) => {
              response = data;
              this.recommendedJobsList = JSON.parse(
                response.replace(/'/g, '"').replace(/: None/g, ': "None"')
              );
              console.log(this.recommendedJobsList);
              this.recommendedJobsList.forEach((job) => {
                if (job.cd) {
                  let startDate = new Date(job.cd).getTime();
                  let endDate = new Date().getTime();
                  let timeDifference = endDate - startDate;
                  let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                  let hours = Math.floor(
                    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                  );
                  let minutes = Math.floor(
                    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                  );
                  let seconds = Math.floor(
                    (timeDifference % (1000 * 60)) / 1000
                  );
                  console.log(
                    days + ' Days ',
                    hours +
                      ' Hours ' +
                      minutes +
                      ' Minutes ' +
                      seconds +
                      ' Seconds '
                  );
                  job.cd = days
                    ? days + ' Days '
                    : hours
                    ? hours + ' Hours '
                    : minutes
                    ? minutes + ' Minutes '
                    : seconds
                    ? seconds + ' Seconds '
                    : '';
                }
              });
            });
          this.openSnackBar(res.message, '');
        } else {
          this.spinner.hide();
          this.recommendedJobsList = [];
        }
      },
      (error) => {
        // this.spinner.hide();
        this.spinner.hide();
        // this.openSnackBar(error, '');
        this.openSnackBar('Something went wrong! Please try again', '');
      }
    );
  }
  openSnackBar(message: string, action: string): void {
    // Method to show alert when any api calls
    this._snackBar.open(message, action, { duration: 3000 });
  }
  onJobClick(job: any) {

    console.log(job.job_tle);
    this.showJobPreview = true;
  }

  jobPreview(jobType, jobDetails) {
    console.log(jobType,jobDetails,"jobType,jobDetails.");
    this.jobDetails = jobDetails;
    this.jobType = jobType;
    this.showJobPreview = true;
  }



  onTabChange(event) {
    this.tabName = event.value.toLowerCase();
    console.log('this.tabName jobs:-  ', this.tabName);
    switch (this.tabName.toLowerCase()) {
      case 'my-jobs':
        break;
      case 'post':
        this.showCreateGroup = true;
        break;
      case 'manage':
        break;
      default:
        break;
    }
  }
  async saveJob(job: any) {
    job.ap_sts = "saved";
     // Method to get my groups
     const formData = {
      uid: this.authService.userValue.uid,
      jobID: job.job_id
    };

    console.log(formData);

    // this.spinner.show();
    (await this.jobsService.saveJob(formData)).subscribe(
      (res: any) => {
        // this.spinner.hide();
        // this.myjobsList = [];
        let response;
        if (res.status) {
          this.spinner.hide();
          console.log(res);
          this.apiEndPointService
            .decodeAesToken(this.authService.userValue.uid, res.data)
            .then((data) => {
              response = data;
              // this.recommendedJobsList = JSON.parse(
              //   response.replace(/'/g, '"').replace(/: None/g, ': "None"')
              // );
              console.log(response);
            });
          this.openSnackBar(res.message, '');
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        // this.spinner.hide();
        this.spinner.hide();
        // this.openSnackBar(error, '');
        this.openSnackBar('Something went wrong! Please try again', '');
      }
    );
  }

  async unsaveJob(job: any) {
    job.ap_sts = "";
    // Method to get my groups
    const formData = {
     uid: this.authService.userValue.uid,
     jobID: job.job_id
   };

   console.log(formData);

   // this.spinner.show();
   (await this.jobsService.unsaveJob(formData)).subscribe(
     (res: any) => {
       // this.spinner.hide();
       // this.myjobsList = [];
       let response;
       if (res.status) {
         this.spinner.hide();
         console.log(res);
         this.apiEndPointService
           .decodeAesToken(this.authService.userValue.uid, res.data)
           .then((data) => {
             response = data;
             // this.recommendedJobsList = JSON.parse(
             //   response.replace(/'/g, '"').replace(/: None/g, ': "None"')
             // );
             console.log(response);
           });
         this.openSnackBar(res.message, '');
       } else {
         this.spinner.hide();
       }
     },
     (error) => {
       // this.spinner.hide();
       this.spinner.hide();
       // this.openSnackBar(error, '');
       this.openSnackBar('Something went wrong! Please try again', '');
     }
   );
 }
 applyJob(job: any) {
  this.showJobPreview = false
  this.showJobApplyPreview = true;

}
}