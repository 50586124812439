<div [ngClass]="authService.isSpecialComponentActive ? 'entire-routing-section' : 'special-component-active'">
    <router-outlet></router-outlet>
</div>
<div class="container-fluid w-100 p-0 m-0 position-relative">

    <!-- Top Navbar Section -->
    <ng-container *ngIf="showNavBar && !isRole">
        <section id="navbar" class="navbar-section w-100 row p-0 m-0 align-items-center justify-content-between">

            <!-- Nushift Connect Logo -->
            <div class="nushift-connect-logo col-md-2"></div>

            <!-- Search Field Section -->
            <form class="search-field col-md-3" [formGroup]="searchForm">
                <mat-form-field appearance="" class="w-100">
                    <input matInput formControlName="search" class="" placeholder="Search" />
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
            </form>

            
            <!-- Menu List Items Section -->
            <div class="col-md-5 row menu-list p-0 m-0 align-items-center justify-content-around align-content-center">
                <div class="menu-list-item d-flex flex-column align-items-center justify-content-between"
                    [ngStyle]="{'transform': (icon?.clicked) ? 'scale(1.1)' : '', 'margin': icon?.style}"
                    (click)="navItemClick(icon['title'], i, 'menu-list')"
                    [ngClass]="icon.class" *ngFor="let icon of menuList; let i=index;">
                    <div class="menu-list-icon-div" [ngClass]="(icon?.title && icon?.title.toLowerCase() == 'profile') ? 'rounded-circle' : ''"
                        [ngStyle]="{ 'background-image': 'url('+ ((icon?.title && icon?.title.toLowerCase() != 'profile') ? icon?.img : l_i_u_d?.ppic ? l_i_u_d?.ppic : '../../../assets/img/updated-nushift-logo.png') +')', 'height': icon?.height+'px', 'width': icon?.width+'px' }">
                    </div>
                    <div class="px-3 py-1 mt-2 text-light" [ngClass]="(icon?.clicked) ? 'menu-list-text bg-light' : ''"
                        [hidden]="!icon.icon_text">{{icon?.icon_text}}</div>
                </div>
            </div>

            <!-- More Options List Section -->
            <div class="col-md-2 d-flex justify-content-between more-list py-0 m-0 align-items-center">
                <div class="menu-list-item d-flex flex-column align-items-center justify-content-between p-0 m-0"
                    (click)="$event.stopPropagation();$event.preventDefault();navItemClick(icon['title'], i, 'more-list')"
                    [ngClass]="icon.class" *ngFor="let icon of more_navbar_list; let i=index;"
                    [ngStyle]="{ 'flex': icon?.style }" [matMenuTriggerFor]="icon.icon_text.toLowerCase() == 'more' ? moreScreens : null">
                    <div class="menu-list-icon-div"
                        [ngStyle]="{ 'background-image': 'url('+ icon?.img +')', 'height': icon?.height+'px', 'width': icon?.width+'px' }">
                    </div>
                    <div class="px-3 py-1 mt-2 text-light" [ngClass]="(icon?.clicked) ? 'menu-list-text bg-light' : ''"
                        [hidden]="!icon.icon_text">{{icon?.icon_text}}</div>
                </div>
                <mat-menu #moreScreens="matMenu" class="more-screens-list-menu" yPosition="below">
                   <div class="p-1 more-screens-list m-3" *ngFor="let more_item of more_screens_list" (click)="moreScreenClick(more_item)">
                    {{more_item.title}}
                   </div>
                </mat-menu>
            </div>
        </section>
    </ng-container>
    <!-- NOTIFICATIONS SECTION -->

    <app-notifications *ngIf="isDrawerOpen" [isOpen]="isDrawerOpen">
    </app-notifications>
      
      
    <!-- <mat-sidenav #drawer mode="side" position="end" opened="false" >
        <app-notifications [drawer]="drawer"></app-notifications>
      </mat-sidenav> -->

 
    

    <!-- Side Navbar Section -->
    <ng-container *ngIf="showSideNav && !isRole">
        <div class="side-navbar-section position-fixed">
        
            <!-- <div [ngSwitch]="coming_from"> -->
                <div *ngIf="coming_from.toLowerCase() == 'home' || coming_from.toLowerCase() == 'groups' || coming_from.toLowerCase() == 'jobs' || coming_from.toLowerCase() == 'events'">
                    <mat-card class="bg-light p-0 rounded-0">
                        <!-- User Details Div -->
                        <div
                            class="logged-in-user-details d-flex flex-column align-items-center justify-content-between border-bottom px-3 py-3">
                            <div class="user-profile rounded-circle"
                                [ngStyle]="{ 'background-image': 'url('+(l_i_u_d?.ppic ? l_i_u_d?.ppic : '../../../assets/img/updated-nushift-logo.png')+')' }"></div>
                            <h2 class="m-0 text-dark text-center">{{l_i_u_d?.name}}</h2>
                            <p class="spclztn-name p-0 m-0">{{l_i_u_d?.specialization_name}}</p>
                        </div>
        
                        <!-- More Options Div -->
                        <div class="more-options-div">
                            <!-- View More List Div -->
                        <div class="view-more-div">
                            <mat-accordion class="view-more-accordion m-0">
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('view-more')" (mouseleave)="restoreBackgroundImage('view-more')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="view-more-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'view-more' || restored_type == 'view-more') ? 'url(../assets/home_screen_images/eye.png)' : 'url(../assets/home_screen_images/eye_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">View More</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
        
                        <!-- Groups List Div -->
                        <div class="groups-div">
                            <mat-accordion class="groups-accordion m-0">
                                <mat-expansion-panel class="p-0 m-0" [disabled]="true">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('groups')" (mouseleave)="restoreBackgroundImage('groups')" (click)="clickSideNavItem('groups')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="groups-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'groups' || restored_type == 'groups') ? 'url(../assets/home_screen_images/groups_icon.png)' : 'url(../assets/home_screen_images/groups_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">Groups</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
        
                        <!-- Suggestions List Div -->
                        <div class="suggestions-div">
                            <mat-accordion class="suggestions-accordion m-0">
                                <mat-expansion-panel class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('suggestions')" (mouseleave)="restoreBackgroundImage('suggestions')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="suggestions-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'suggestions' || restored_type == 'suggestions') ? 'url(../assets/home_screen_images/suggestions_icon.png)' : 'url(../assets/home_screen_images/suggestions_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">Suggestions</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
        
                        <!-- Recent Profile Viewers List Div -->
                        <div class="recent-profile-viewers-div">
                            <mat-accordion class="recent-profile-viewers-accordion m-0">
                                <mat-expansion-panel class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('recent-profile-views')" (mouseleave)="restoreBackgroundImage('recent-profile-views')" (click)="clickSideNavItem('recent-profile-views')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="recent-profile-viewers-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'recent-profile-views' || restored_type == 'recent-profile-views') ? 'url(../assets/home_screen_images/recent_profile_views_icon.png)' : 'url(../assets/home_screen_images/recent_profile_views_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">Recent Profile Viewers</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
        
                        <!-- Events List Div -->
                        <div class="events-div">
                            <mat-accordion class="events-accordion m-0">
                                <mat-expansion-panel class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('events')" (mouseleave)="restoreBackgroundImage('events')" (click)="clickSideNavItem('events')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="events-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'events' || restored_type == 'events') ? 'url(../assets/home_screen_images/events_icon.png)' : 'url(../assets/home_screen_images/events_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">Events</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
        
                        <!-- Jobs List Div -->
                        <div class="jobs-div">
                            <mat-accordion class="jobs-accordion m-0">
                                <mat-expansion-panel class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeBackgroundImage('jobs')" (mouseleave)="restoreBackgroundImage('jobs')" (click)="clickSideNavItem('jobs')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="jobs-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type != 'jobs' || restored_type == 'jobs') ? 'url(../assets/home_screen_images/jobs_icon.png)' : 'url(../assets/home_screen_images/jobs_white_icon.png)' }">
                                            </div><span class="px-2 font-size-4">Jobs</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        </div>
                    </mat-card>
                </div>
                <div *ngIf="coming_from.toLowerCase() == 'networks'">
                    <mat-card class="bg-light p-0 rounded-0">
                        <!-- More Options Div -->
                        <div class="networks-more-options-div">
                            <!-- suggestions List Div -->
                        <div class="networks-suggestions-div">
                            <mat-accordion class="networks-accordion m-0" *ngFor="let networkItem of networks_menuList; let i=index;">
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    class="p-0 m-0">
                                    <mat-expansion-panel-header class="m-0" (mouseover)="changeNetworksBackgroundImage(networkItem.title)" (mouseleave)="restoreNetworksBackgroundImage(networkItem.title)" (click)="newtworksSideItemClick(networkItem['title'], i, 'networks-menu-list')">
                                        <mat-panel-title
                                            class="d-flex align-items-center align-content-center font-weight-bold m-0 px-2">
                                            <div class="networks-icon p-0 m-0"
                                                [ngStyle]="{ 'background-image': (hovered_type.toLowerCase() == networkItem.title.toLowerCase()) ? networkItem?.img_white : networkItem?.img }">
                                            </div><span class="px-2 font-size-4">{{networkItem.title}}</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        </div>
                    </mat-card>
                </div>
                <div *ngIf="coming_from.toLowerCase() == 'chat'">
                    chat......
                </div>
                <!-- <p *ngSwitchDefault>Default Element</p> -->
              <!-- </div> -->

            
        </div>
    </ng-container>

    <!-- TO SHOW THE LOGGED-IN USER ROLES LIST AND SELECTING PARTICULAR ROLE -->
<div class="container-fluid select-role-div" *ngIf="isRole">
    <div class="title position-relative">
      <b class="role-text">Please Select Your Role to Proceed Further!</b>
    </div>
  </div>
  
  <!-- List of Roles Section -->
  <div class="all-roles d-flex align-content-start flex-wrap px-5 mx-1" *ngIf="isRole">
    <div class="" *ngFor="let role of myRoles;">
      <div class="d-flex flex-column align-items-center align-content-center">
        <mat-card class="roles m-4" (click)="roleClick(role)">
          <div class="role-image rounded"
            [ngStyle]="{ 'background-image': 'url(' + (role.profile_picture ? role.profile_picture : '../assets/img/updated-nushift-logo.png') + ')' }">
          </div>
        </mat-card>
        <div class="mb-2 text-center" style="width: 65% !important;"><b>{{role?.name}}</b></div>
      </div>
    </div>
  </div>
</div>

<!-- SHOWING LOADER ON THE WEBSITE -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
    <b style="color: white"> Loading... </b>
  </ngx-spinner>
