import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { Observable } from 'rxjs';
import { NetworksModel } from './networks.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService
  ) { }

  // async getNewAnalytics(payload: any): Promise<Observable<any>> {
  //   // this.apiEndpointsService.getAesToken(payload)
  //   this.apiEndpointsService.signToken(localStorage.getItem('privateKey'), payload.uid, payload);
  //   let encryptedPayload = {
  //     data: localStorage.getItem('aesToken')
  //   }
  //   console.log(encryptedPayload);

  //   return this.apiHttpService.post(
  //     await this.apiEndpointsService.getNewAnalytics(payload),
  //     encryptedPayload
  //   );
  // }


  async getNewAnalytics(payload: any): Promise<Observable<any>> {
    return this.apiHttpService.get(
      await this.apiEndpointsService.getNewAnalytics(payload)
    );
  }

  //getAnalytics
  async getAnalytics(payload: any): Promise<Observable<any>> {
    return this.apiHttpService.get(
      await this.apiEndpointsService.getAnalytics(payload)
    );
  }

  // getNetworksData(formData: any): Observable<NetworksModel> {
  //   return this.apiHttpService.get(
  //     this.apiEndpointsService.getNetworksData(formData)
  //   );
  // }



}
