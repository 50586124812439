<!-- <span class="text-right w-100"><mat-icon style="font-size: 30px" mat-dialog-close (click)="closeDialog()"> close</mat-icon></span> -->
<!-- <h2 mat-dialog-title class="">SEND AN ENQUIRY</h2> -->

<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">SEND AN ENQUIRY</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="Nushift">
  </button>
</div>
<!-- <mat-dialog-content class="mat-typography"> -->
<div class="container-fluid row d-block">
  <ng-container>
    <!-- <div class="container"> -->
    <form [formGroup]="enquiryForm">
      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Full Name <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong>Full Name<span class="text-danger">*</span></strong> </mat-label>

          <input matInput formControlName="name" placeholder="What people call you?" />
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Phone Number <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong> Phone Number </strong><span class="text-danger">*</span> </mat-label>

          <input matInput formControlName="phone" placeholder="How can we contact you?" />
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Email <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong> Email </strong> <span class="text-danger">*</span></mat-label>

          <input matInput formControlName="email" placeholder="How can we reach you?" />
        </mat-form-field>
      </div>

      <!-- <div class="col-100">
        <mat-form-field appearance="standard" floatLabel='always' class="col-100">
          <mat-label class="font-weight-bold h5"> Heading <span class="text-danger">*</span></mat-label>
          <input matInput formControlName="subject" placeholder="What's the heading?" />
        </mat-form-field>
      </div> -->

      <!-- <div class="col-100">
        <mat-label class="h6">Send Enquiry To<span class="text-danger">*</span></mat-label>
        <div>
          <mat-radio-group aria-label="Select an option" formControlName="sendTo">
            <mat-radio-button class="mr-2" value="D"><small>Doctors</small>
            </mat-radio-button>
            <mat-radio-button class="mr-2" value="H"><small>Hospitals</small>
            </mat-radio-button>
            <mat-radio-button class="mr-2" value="L"><small>Diagnostic Centers</small>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div> -->

      <!-- <mat-form-field floatLabel='always' appearance="standard" class="w-50 mb-2">
                <mat-label class="text-color">Select User <span class="text-danger">*</span></mat-label>
                <mat-select formControlName="toId">
                  <mat-option value="male" *ngFor="let user of userList">{{user.name}}</mat-option>
                </mat-select>
              </mat-form-field> -->

      <!-- <mat-form-field appearance="standard" floatLabel="always" class="w-50 mb-2">
                <mat-label class="font-poppins font-size-3 ns-text-blue">Select User<span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="to_id" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option (click)="getUserList()" *ngFor="let user of userList" [value]="user.id">
                    {{user.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->

      <!-- <div class="col-100">
        <mat-form-field appearance="standard" floatLabel="always" class="col-100">
          <mat-label class="font-weight-bold h5">Select User<span class="text-danger">*</span>
          </mat-label>
          <input matInput formControlName="to_id" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="selectUser(user.uid)" *ngFor="let user of userList$ | async" [value]="user.name">
              {{user.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div> -->

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5">Enquiry Related To<span class="text-danger">*</span>

          </mat-label> -->
          <mat-label style="color: black;"> <strong>Enquiry Related To</strong> <span class="text-danger">*</span></mat-label>

          <!-- <input matInput formControlName="regarding"/> -->
          <mat-select formControlName="regarding">
            <mat-option *ngFor="let enq of enquiriesList" [value]="enq.code">
              {{enq.name}}
            </mat-option>
            <!-- <mat-option *ngFor="let industry of industriesList" [value]="industry.id">{{industry.name}}</mat-option> -->
          </mat-select>
          <!-- <mat-autocomplete #auto="matAutocomplete"> -->

          <!-- </mat-autocomplete> -->
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <mat-label style="color: black;"> <strong> Description </strong><span class="text-danger">*</span> </mat-label>
          <textarea formControlName="description" matInput placeholder=""></textarea>
        </mat-form-field>
      </div>
    </form>
    <div class="col-100 float-right">
      <mat-dialog-actions>
        <!-- <button mat-button class="button-cancel mr-2" mat-dialog-close>Cancel</button> -->
        <button mat-button class="btn-save" [disabled]="enquiryForm.invalid" (click)="onSubmit()"><span [ngStyle]="{'cursor': enquiryForm.valid ? 'pointer !important' : 'not-allowed !important'}">Submit</span></button>
      </mat-dialog-actions>
    </div>
  </ng-container>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
  <b style="color: white"> Loading... </b>
</ngx-spinner>
<!-- </mat-dialog-content> -->