<!-- <h3 mat-dialog-title>Contact Us</h3> -->
<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">Contact Us</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="Nushift">
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="d-flex justify-content-center flex-column">
    <form [formGroup]="contactUsForm" class="d-flex flex-wrap ">
      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong>Full Name</strong> </mat-label>

        <input readonly formControlName="name" matInput placeholder="What people call you?" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong>Contact Number</strong> </mat-label>

        <input readonly formControlName="phone" matInput placeholder="How can we contact you?" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong>Email</strong> </mat-label>

        <input readonly formControlName="email" matInput placeholder="How can we reach you?" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong>Query</strong> </mat-label>

        <textarea formControlName="description" matInput placeholder="Any comments?"></textarea>
      </mat-form-field>
    </form>
    <p class="">
      <!-- <button mat-button class="button-cancel mr-2" mat-dialog-close>Cancel</button> -->
      <button mat-button class="btn-save " (click)="onSubmit()"> Save</button>
    </p>
  </div>
</mat-dialog-content>
