<div class="container-fluid w-100 my-3 position-absolute dashboard-main-div">
  <div class="main">
    <h5 class="welcome">Welcome,</h5>
    <h2 class="name">Dr. Azharuddin</h2>
    <h2 class="mt-2">Referrals</h2>

    <div class="referrals d-flex justify-content-around text-center">
      <mat-card style="width: 150px; height: 100px">
        <img
          class="m-1"
          src="../../../../assets/dashboard_images/clock.png"
          alt="../../../assets/img/updated-nushift-logo.png"
          height="30"
          width="30"
        />
        <h3>Pending {{ referralPendingCount }}</h3>
      </mat-card>
      <mat-card style="width: 150px; height: 100px">
        <img
          class="m-1"
          src="../../../../assets/dashboard_images/arrow.png"
          alt="../../../assets/img/updated-nushift-logo.png"
          height="30"
          width="30"
        />
        <h3>Recieved {{ referralRecievedCount }}</h3>
      </mat-card>
      <mat-card style="width: 150px; height: 100px">
        <img
          class="m-1"
          src="../../../../assets/dashboard_images/send.png"
          alt="../../../assets/img/updated-nushift-logo.png"
          height="30"
          width="30"
        />
        <h3>Sent {{ referralSentCount }}</h3>
      </mat-card>
    </div>

    <!-- Followers data -->

    <mat-card class="feed-chart-div mt-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="followers-count">Followers</h3>
          <h3 class="followers-count">{{ totalFollowersCount }}</h3>
        </div>
        <div>
          <h3 class="text-center font-weight-bold">Select a Range</h3>
          <div>
            <!-- <button class="mx-1 btn-followers" (click)="folowersRangeBtn('24h')">24h</button> -->
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('week')"
            >
              1W
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('month')"
            >
              1M
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('year')"
            >
              1Y
            </button>
            <button class="mx-1 btn-followers" (click)="getNewAnalytics('all')">
              ALL
            </button>
          </div>
        </div>
      </div>

      <div class="feed-container">
        <div class="feed-graph-container">
          <canvas
            baseChart
            style="display: block; height: 350px"
            [datasets]="followersChartData"
            [labels]="followersChartLabels"
            [chartType]="followersLineChartType"
            [options]="followersChartOptions"
            [colors]="followersChartColors"
            [legend]="followersChartLegend"
          >
          </canvas>
        </div>
      </div>
    </mat-card>

    <!-- Feed data -->

    <mat-card class="feed-chart-div mt-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="followers-count">Feed - April</h3>
          <h3 class="followers-count">50</h3>
        </div>
        <div>
          <h3 class="text-center font-weight-bold">Select a Range</h3>
          <div>
            <!-- <button class="mx-1 btn-followers" (click)="feddRangeBtn('24h')">24h</button> -->
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('week')"
            >
              1W
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('month')"
            >
              1M
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('year')"
            >
              1Y
            </button>
            <button class="mx-1 btn-followers" (click)="getNewAnalytics('all')">
              ALL
            </button>
          </div>
        </div>
      </div>

      <div class="feed-container">
        <div class="feed-graph-container">
          <canvas
            baseChart
            style="display: block; height: 350px"
            [datasets]="feedChartData"
            [labels]="feedChartLabels"
            [chartType]="feedLineChartType"
            [options]="feedChartOptions"
            [colors]="feedChartColors"
            [legend]="feedChartLegend"
          >
          </canvas>
        </div>
        <!-- <div class="feed-info-container d-flex justify-content-between">
          <div
            class="text-center pointer"
            (click)="feedAction('total', '#E32020')"
          >
            <img
              class="feed-img"
              src="../../../../assets/dashboard_images/totalcount.png"
              alt="count"
            />
            <br />
            <img
              class="feed-btn-img"
              src="../../../../assets/dashboard_images/red_fill.png"
              alt="tot"
            />
            <button class="feed-button">Total Count</button>
          </div>

          <div
            class="text-center pointer"
            (click)="feedAction('share', '#FBDF4A')"
          >
            <img
              class="feed-img"
              src="../../../../assets/dashboard_images/share.png"
              alt="share"
            />
            <br />
            <img
              class="feed-btn-img"
              src="../../../../assets/dashboard_images/yellow_fill.png"
              alt="share"
            />
            <button class="feed-button">shares</button>
          </div>

          <div
            class="text-center pointer"
            (click)="feedAction('comment', '#71EE76')"
          >
            <img
              class="feed-img"
              src="../../../../assets/dashboard_images/comments.png"
              alt="comments"
            />
            <br />
            <img
              class="feed-btn-img"
              src="../../../../assets/dashboard_images/green_fill.png"
              alt="comments"
            />
            <button class="feed-button">comments</button>
          </div>

          <div
            class="text-center pointer"
            (click)="feedAction('like', '#3F8CD2')"
          >
            <img
              class="feed-img"
              src="../../../../assets/dashboard_images/like.png"
              alt="likes"
            />
            <br />

            <img
              class="feed-btn-img"
              src="../../../../assets/dashboard_images/blue_fill.png"
              alt="likes"
            />
            <button class="feed-button">likes</button>
          </div>
        </div> -->
        <div class="feed-info-container d-flex justify-content-between">
          <div
            *ngFor="let button of buttons"
            class="text-center pointer"
            (click)="feedAction(button.id)">
            <img class="feed-img" [src]="button.defaultImage" [alt]="button.id" />
            <br />
            <img
              class="feed-btn-img"
              [src]="button.selected ? button.selectedImage : button.unselectedImage"
              [alt]="button.id"
            />
            <button class="feed-button">{{ button.id | titlecase }}</button>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- Conections data -->

    <mat-card class="connections-chart-div mt-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="followers-count">Total Connections</h3>
          <h3 class="followers-count">20</h3>
        </div>
        <div>
          <img
            class="feed-btn-img"
            src="../../../../assets/dashboard_images/blue_outer.png"
            alt="share"
          />
          <button class="mx-1 btn-followers feed-button">
            {{ connectionsChartData[0].data[0] + "%" }}
          </button>
          <img
            class="feed-btn-img"
            src="../../../../assets/dashboard_images/yellow_outer.png"
            alt="share"
          />
          <button class="mx-1 btn-followers feed-button">
            {{ connectionsChartData[0].data[1] + "%" }}
          </button>
          <img
            class="feed-btn-img"
            src="../../../../assets/dashboard_images/green_outer.png"
            alt="share"
          />
          <button class="mx-1 btn-followers feed-button">
            {{ connectionsChartData[0].data[2] + "%" }}
          </button>
        </div>
      </div>

      <div class="connections-container">
        <div class="connections-graph-container">
          <canvas
            baseChart
            style="display: block"
            [datasets]="connectionsChartData"
            [labels]="connectionsChartLabels"
            [chartType]="connectionsLineChartType"
            [options]="connectionsChartOptions"
            [colors]="connectionsChartColors"
            [legend]="connectionsChartLegend"
          >
          </canvas>
        </div>
      </div>
    </mat-card>

    <!-- Feedback data -->

    <mat-card class="feedback-chart-div mt-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="followers-count">Feedback Count</h3>
          <h3 class="followers-count">50</h3>
        </div>
        <div>
          <h3 class="text-center font-weight-bold">Select a Range</h3>
          <div>
            <!-- <button class="mx-1 btn-followers" (click)="feedbackRangeBtn('24h')">24h</button> -->
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('week')"
            >
              1W
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('month')"
            >
              1M
            </button>
            <button
              class="mx-1 btn-followers"
              (click)="getNewAnalytics('year')"
            >
              1Y
            </button>
            <button class="mx-1 btn-followers" (click)="getNewAnalytics('all')">
              ALL
            </button>
          </div>
        </div>
      </div>

      <div class="feed-container">
        <div class="feed-graph-container">
          <canvas
            baseChart
            style="display: block; height: 350px"
            [datasets]="feedbackChartData"
            [labels]="feedbackChartLabels"
            [chartType]="feedbackLineChartType"
            [options]="feedbackChartOptions"
            [colors]="feedbackChartColors"
            [legend]="feedbackChartLegend"
          >
          </canvas>
        </div>
        <div class="feed-info-container d-flex justify-content-between">
          <div
            *ngFor="let button of feedbackButtons"
            class="text-center pointer"
            (click)="referralfeedbackAction(button.id)"
          >
            <!-- <img class="feed-img" [src]="button.defaultImage" [alt]="button.id"> -->
            <br />
            <img
              class="feed-btn-img"
              [src]="
                button.selected ? button.selectedImage : button.unselectedImage
              "
              [alt]="button.id"
            />
            <button class="feed-button">
              {{ button.id.replace("_", " ") }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
