import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { NavbarService } from '../services/navbar.service';
@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  enquiryForm = new FormGroup({
    sendTo: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl(''),
    description: new FormControl('', Validators.required),
    regarding: new FormControl('', Validators.required),
    to_id: new FormControl(''),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
    ]),
  });
  enquiriesList: any = ['Delete Account','Deativate Account'];
  alert: string = '';
  isUpdate : boolean = false;

  constructor(public nav: NavbarService,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }


  ngOnInit(): void {

    this.nav.navbarHide();
    this.nav.footerShow();
    this.nav.setfooterNotFixed();
   
  }
  // ngDestroy() {
  //   this.subscription.unsubscribe();
  // }

  async onSubmit() {  
    this.isUpdate = false;
    if (this.enquiryForm.invalid) {
      this.isUpdate = true
      this.alert = "Please fill in all required fields.";
      this.openSnackBar('Please fill in all required fields.', 'Close');
      return;
    }
    
    // Calling save api on clicking of SUBMIT button
    this.spinner.show();
    let formData = this.enquiryForm.value;
    console.log(formData);
    // alert('Account Deletion is Completed');
        // Simulate a delay of 2 seconds
        setTimeout(() => {
          this.spinner.hide();
          // Add your form submission logic here
          this.isUpdate = true;
          this.alert = "We have received your request and we will process within 7 days";
          this.enquiryForm.reset(); // Clear all fields after submission
          this.openSnackBar('We have received your request and we will process it within 7 days', 'Close');
        }, 2000);
  }

  openSnackBar(message: string, action: string): void {  //To show the alert messages when api fails/gets success
    this._snackBar.open(message, action, { duration: 3000 });
  }

}
