<div class="container-fluid row">
    <div class="col-md-6 login-img-div p-3 border-right">
      <img src="../../../assets/nushift_connect_login_img.png" alt="" height="550" width="600">
    </div>
    <div class="col-md-6 login-section-div d-flex flex-column">
      <!-- <div id="nushift-logo-img"> -->
        <div class="login-close" (click)="onCloseDrawer()">
          <mat-icon class="close_icon m-4 float-right">close</mat-icon>
        </div>
      <!-- </div> -->
      <div class="mt-2 mr-5 mb-3 ml-3 justify-content-center align-items-center">
        <h1 class="nushift-black mb-4 hello"><strong>Hello!! Login as</strong></h1>
        <form [formGroup]="loginForm" class="form-container">
          <div class="row">
            <!-- <div class="col-md-12 mb-1 login-as">
              <mat-label for="userid" style="font-weight: 600; color: black;font-size: 15px !important;">Login As <span
                  class="text-danger">*</span></mat-label>
              <mat-radio-group aria-label="Select an option" formControlName="as"
                class="d-flex align-items-center justify-content-between mb-2 mt-3" required>
                <mat-radio-button class="" value="D">Doctor</mat-radio-button>
                <mat-radio-button class="" value="H">Hospital</mat-radio-button>
                <mat-radio-button class="" value="L">Diagnostic Center</mat-radio-button>
              </mat-radio-group>
            </div> -->
            <div class="col-md-12 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Email/Phone
                  number <span class="text-danger">*</span></mat-label>
                <input matInput formControlName="userid" class="mt-3" />
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; margin-top: 10px; color: black;font-size: 20px !important;">Password
                  <span class="text-danger">*</span></mat-label>
                <input matInput formControlName="pwd" class="mt-3" [type]="hide ? 'password' : 'text'" />
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <!-- <mat-error *ngIf="loginForm.get('pwd').errors.pattern">Password is invalid</mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="showOtpField">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; margin-top: 10px; color: black;font-size: 20px !important;">Enter OTP
                  <span class="text-danger">*</span></mat-label>
                <input matInput formControlName="otp" class="mt-3" maxlength="4"/>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-3 d-flex flex-column align-items-center justify-content-between">
            <a (click)="gotoForgotPassword()" class="w-100 cursor forgot-password">Forgot Password?</a>
            <button type="submit" *ngIf="!showOtpField" class="w-100 btn btn-block sign-in-btn" style="margin-top: 10px"
              [disabled]="loginForm.get('userid').invalid || loginForm.get('pwd').invalid" (click)="login()">
              Login
            </button>
            <button class="w-100 btn btn-block sign-in-btn" *ngIf="showOtpField" [disabled]="!loginForm.get('otp').value || loginForm.get('otp').value?.length != 4" style="margin-top: 10px" (click)="verifyOTP()">
              Login
            </button>
            <div class="w-100 d-flex justify-content-center font-size-5 my-3">
              <span class="nushift-black mt-2" id="new-user-label">New User?
                <a class="ml-2 cursor nushift-blue bold" (click)="gotoRegister()" id="register-label"
                  style="font-weight: 600">Register</a>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
    <b style="color: white"> Loading... </b>
  </ngx-spinner>