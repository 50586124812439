<!-- <h3 mat-dialog-title>About Us</h3> -->

<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">About Us</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="Nushift">
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="col-xs-12 col-sm-10 margin-center center-block">
    <ul class="lower-rom-list">
      <li *ngFor="let a of about">
        {{a.abtus}}
      </li>
    </ul>
  </div>
</mat-dialog-content>
