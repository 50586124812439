import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
@Component({
  selector: 'app-get-invited',
  templateUrl: './get-invited.component.html',
  styleUrls: ['./get-invited.component.scss']
})
export class GetInvitedComponent implements OnInit {

  mobileStatusMessage: string;
  showSpinner: boolean = false;
  public isOtpVerified: boolean = false;
  inviteForm: FormGroup = new FormGroup({
    mobile: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    app: new FormControl('d'),
    email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/)]),
  });

   constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private spinner: NgxSpinnerService,
    private nav: NavbarService
  ) { }

  ngOnInit() { 
    this.nav.navbarHide();
    this.nav.footerHide();
  }


  async invite() {
    this.spinner.show();
    let payload = {
      mobile: this.inviteForm.value.mobile,
      email: this.inviteForm.value.email,
      app: 'd',
      first_name: this.inviteForm.get('firstName').value,
      last_name: this.inviteForm.get('lastName').value,
    };
    (await this.authService.getInvited(payload)).subscribe((res) => {
      this.spinner.hide();
      if (res?.status) {
        console.log(res);
        this.openSnackBar(res.message, '');
        this.router.navigate(['landing'], { replaceUrl: true });
      } else {
        this.openSnackBar(res.message, '');
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wrong! Please try again', '');
    });
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
