<div class="container-fluid text-center" style="height: 100vh; width: 100%;">
  <h3 mat-dialog-title class="m-2 fw-bold text-center">Nushift Connect - Delete Account</h3>

  <ng-container>
    <!-- <div class="container"> -->
    <form [formGroup]="enquiryForm" class="m-3">
      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Full Name <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong>Full Name <span class="text-danger">*</span></strong> </mat-label>

          <input matInput formControlName="name" placeholder="What people call you?" />
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Phone Number <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong> Phone Number <span class="text-danger">*</span></strong> </mat-label>

          <input matInput formControlName="phone" placeholder="How can we contact you?" />
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Email <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong> Email <span class="text-danger">*</span> </strong> </mat-label>

          <input matInput formControlName="email" placeholder="How can we reach you?" />
        </mat-form-field>
      </div>

     

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5">Enquiry Related To<span class="text-danger">*</span>

          </mat-label> -->
          <mat-label style="color: black;"> <strong>Choose any of the following action type <span class="text-danger">*</span></strong> </mat-label>

          <mat-select formControlName="regarding" placeholder="Please select option">
            <mat-option *ngFor="let enq of enquiriesList" [value]="enq">
              {{enq}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-100">
        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <!-- <mat-label class="font-weight-bold h5"> Description <span class="text-danger">*</span></mat-label> -->
          <mat-label style="color: black;"> <strong> Description </strong><span class="text-danger">*</span> </mat-label>

          <textarea formControlName="description" matInput placeholder="Please enter Description"></textarea>
        </mat-form-field>
      </div>
    </form>
    <div class="col-100">

      <p *ngIf="isUpdate">{{alert}}</p>

      <mat-dialog-actions>
          <!-- <button mat-button class="btn-save" [disabled]="enquiryForm.invalid" (click)="onSubmit()"><span [ngStyle]="{'cursor': enquiryForm.valid ? 'pointer !important' : 'not-allowed !important'}">Submit</span></button> -->
        <button mat-button class="btn-save" (click)="onSubmit()"><span [ngStyle]="{'cursor': enquiryForm.valid ? 'pointer !important' : 'not-allowed !important'}">Submit</span></button>
      </mat-dialog-actions>

    </div>
  </ng-container>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
  <b style="color: white"> Loading... </b>
</ngx-spinner>
<!-- </mat-dialog-content> -->