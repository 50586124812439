<div class="main-div">
    <h3 mat-dialog-title class="text-center para-bold mt-2">Nushift Connect - Privacy Policy</h3>
    <div class="mat-typography">
      <div class="col-xs-12 col-sm-10 margin-center center-block mb-5">
        <p>
          <i>
            <strong>Disclaimer:</strong> The Company can modify this Policy
            unilaterally at any time without notice. Modification may be
            necessary, among other reasons, to maintain compliance with applicable
            laws and regulations or accommodate organizational changes within the
            Company.
          </i>
        </p>
        <p>
          We understand that the privacy of your personal information is important
          and hence we wouldlike to keep you informed and empowered with respect
          to your privacy on nushift.in(“Website”),mobile app, Nushift Connect(the
          “App”) (collectively as “Platform”). This Privacy Policy(“Policy”)
          applies to Website, the App and its related services (the “Services”)
          operated andrendered by Nushift Technologies Private Limited
          (“Company”). This Policy describes use andsharing of personally
          identifiable information submitted by its registered users receiving
          theServices (the “User(s)” or “You” or “Your”) in receiving the
          Services, such information beingrelated to you or other users
          downloading, accessing or using the Platform. The Services offeredadhere
          to all the laws, rules, regulations and policies (“Applicable Laws”)
          with regard to thecollection, use, disclosure, retention and protection
          of your information including Personal Data,that Company is likely to
          have access to as a result of your use of the Platform.
        </p>
  
        <ol class="custom-list">
          <!-- First Point -->
          <li>
            <p class="para-bold">What the Company Collects From You</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                Any data about any you, by which you, as a person can be
                identified, including withoutlimitation, your name, gender,
                business name, its address, email address, date of birth,date of
                incorporation, any identity proof number, phone number, Phone book
                /Contacts, location details, IP address, browser details, doctors
                application viz doctorsregistration number, practicing locations,
                appointments, connections and interactioninformation with other
                doctors and patients, patients application viz medical
                conditions,medication, food allergies, appointments with doctors
                and events attended relevant to themedical community, medical
                prescriptions, diagnostic reports, access users media likecamera,
                pictures, audio, and video files etc., to which Company has or is
                likely to haveaccess by virtue of your consensual submission
                (“Personal Data”). Personal Data shallalso include the information
                of such third parties that Company may have access toduring the
                course of providing the Services to you. Company may also collect
                recurringinformation of your geographic locations on a continuous
                basis. Company collects suchPersonal Data from you because of your
                voluntary submission of such information inorder to enjoy the
                Service
              </li>
              <li class="bullet">
                Information collected from website submissions will be used for
                Company’s internalbusiness purposes, including its investigations,
                sales, and marketing. We may use IPaddresses for purposes such as
                calculating usage levels, diagnosing server problems
                andadministering the services. We may also derive your approximate
                location from your IPaddress. The website may include options for
                visitors to submit information to Company,for example, while
                requesting information about a product/service, downloading
                anarticle, etc. Such forms may provide the option of submitting
                personal information, such as your name, job title, company, email
                address, or phone number. In addition, whensubmitting information
                on a Website, your submission will be associated with the
                IPaddress you are then using, which is automatically assigned to
                your computer by yourInternet Service Provider. If you are using
                one of the market leading browsers and areuncomfortable accepting
                cookies from our website, you may erase and/or disable
                theacceptance of cookies in your browsers administrative tools.
                Disabling your browser inthis manner may result in a compromised
                experience of Nushift Connect app or otherwebsites you regularly
                visit.
              </li>
              <li class="bullet">
                Company may also collect such other anonymous data that is not
                associated with orlinked to any Personal Data (“Anonymous Data”)
                and which, by itself, does not permitthe identification of any
                individual User. Anonymous Data includes without limitation,unique
                identifiers of the device on which Company is installed, such as
                VPN,information of WiFi connectivity and such other information.
                Company may also storethis unique identifier, at many times and
                create a profile for you based on suchAnonymous Data.
              </li>
              <li class="bullet">
                In addition, Company shall automatically collect certain other
                information, including butnot limited to usernames, passwords, and
                other security and usage related information,whenever you visit or
                utilize the Services such as Log Data, Analytical Code, TimeStamp,
                Geo Stamp, IP address, version and identification number of the
                App and device,browser type, browser language, the date and time
                of your request, your profile, websitesvisited by you, search
                terms used, your mobile carrier data, platform type, number
                ofclicks, phone number, requested status of other Users and
                various status informationwith regard to the Services provided
                through Nushift connect app (“UsageInformation”). Such Usage
                Information collected is not associated with any PersonalData and
                is only tagged to the unique identifier for a particular device
              </li>
              <li class="bullet">
                Company uses data collection from devices such as “cookies” on
                your device throughwhich user is accessed that assist (a) in
                providing Services; and (b) to allow you to enteryour password
                less frequently during a session. Most cookies are “session
                cookies”. ASession cookie is temporary and is automatically
                deleted from your device at the end of asession. You are always at
                an option to decline these cookies if the application
                permits,although in that case you may not be able to use certain
                features on nushift connect appand you may be required to re-enter
                your password more frequently during a session
              </li>
            </ul>
          </li>
  
          <!-- Second Point -->
          <li>
            <p class="para-bold">How the information collected is being used.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                Company may use the Personal Data and other information collected
                as mentionedabove for any of the following purposes – to
                effectively provide the Services;  for the purposes of software
                verification or administering software upgrades; to allow you to
                participate in surveys regarding Services; to maintain the
                quality of the Services and provide general statistics regarding
                theiruse; to deliver Services, including email notifications,
                confirmations, technical notices,updates, security alerts, and
                support and administrative messages that you mayrequest; archival
                or backup purposes; to alert you about updated information and
                other such new or updated servicesthat may be offered by other
                Users; This information will be used to report better medical
                care for the patients andimprove the engagement of doctors in
                various aspects. Better prepare the doctorsfor future readiness;
                to allow access to exclusive features or functionality of
                Nushiftconnect app. to launch and/or open other mobile
                applications installed on your device based onfunctionality of
                Nushiftconnet app and to resolve disputes, collect fees and
                troubleshoot problem
              </li>
            </ul>
          </li>
  
          <!-- Third Point -->
          <li>
            <p class="para-bold">Sharing of the collected information.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                Company may share the Personal Data received from you: With the
                Company’s affiliates in connection with matters relating to
                theconsumption of Services by you to help detect and prevent
                identity theft, fraud andother potentially illegal acts;
                correlated or multiple accounts to prevent abuse ofServices; and
                to facilitate joint or co-branded services that you request, where
                suchservices are provided by more than one corporate entity other
                than the Company;and if required to do so by law or regulation;
                to its legal counsel, law enforcementofficers, governmental
                authorities who have asserted their lawful authority toobtain the
                information or where the Company has in good faith
                reasonablegrounds to believe that such disclosure is reasonably
                necessary to enforce its Termsof Use or this Privacy Policy. The
                information collected will be shared with doctors on the Platform
                relating topatient’s health, diagnostic data, past medical history
                and medical history of family
              </li>
  
              <li class="bullet">
                The Company shall not rent, sell, or otherwise provide Personal
                Data received from youto third parties without your consent,
                except as described in this Policy or as required bylaw. However,
                the Company and its affiliates reserve the right to share, sell
                and transfersome or all of the Personal Data to other business
                entities should the Company, or itsassets, plan to merge with, or
                be acquired by that business entity.
              </li>
            </ul>
          </li>
  
          <!-- Fourth Point -->
          <li>
            <p class="para-bold">Security of Personal Information.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                All the information of the User is stored in AWS and encrypted.
                The details are sharedwith the requested parties with an OTP based
                verification process. If a patient shares thedocuments with a
                doctor no verification is received by the patient as it is deemed
                that thepatient consent is provided. Sharing of the documents is
                only allowed for a limitedamount of time and the document link
                expires after a certain period of time
              </li>
  
              <li class="bullet">
                The Company has implemented commercially reasonable physical,
                managerial,operational and technical security measures to protect
                the loss, misuse, alteration andsecurity of the Personal Data
                received from you in the Company’s care. The Company’ssecurity
                practices and procedures limit access to Personal Data on need to
                know basisonly. Such measures may include, where appropriate, the
                use of firewalls, secure serverfacilities, implementing proper
                access rights management systems and processes, carefulselection
                of processors, standard authentication and encryption policies for
                its serversand other technically and commercially reasonable
                measures.
              </li>
              <li class="bullet">
                The Company is committed to protecting the security of any
                Personal Data and usesreasonable efforts including a variety of
                security technologies and procedures to helpprotect such Personal
                Data from any unauthorized access, use or disclosure. TheCompany
                will take all reasonable measures in furtherance of the above
                commitment.However, the Company cannot guarantee that the Personal
                Data received from you andother information stored on the servers
                will be absolutely protected. Company’s systemsand the
                communications networks through which you access Nushift Connect
                app maybe subject to security breaches and failures due to
                circumstances beyond Company’sreasonable control. These may
                include computer "hacking", data theft or and physicaltheft among
                others. In such situations, the Company will try to rectify the
                problems assoon as possible
              </li>
              <li class="bullet">
                Any data destroyed shall be disposed of in a manner that protects
                the privacy of thePersonal Data received by Company from you in an
                appropriate manner as per theindustry standard practices and
                norms.
              </li>
              <li class="bullet">
                The User hereby acknowledges and understands that all Personal
                Data provided in usingthe App is with their knowledgeable consent
                and at the User’s own risk.
              </li>
            </ul>
          </li>
  
          <!-- 5th Point -->
          <li>
            <p class="para-bold">Your Obligation.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                As a registered user of the Services, you have certain obligations
                to other users. Some ofthese obligations are imposed by Applicable
                Law and regulations, and others havebecome commonplace for
                Services as offered by Company: you must, at all times, abide by
                the terms and conditions of the then prevailingPrivacy Policy and
                Terms of Use. This includes respecting all intellectual
                propertyrights that may belong to third parties; you must not
                upload or insert any comments that may be deemed to be
                injurious,violent, offensive, racist or xenophobic, or which may
                otherwise violate thepurpose and spirit of Nushift connect app ,
                Nushift technologies private and itscommunity of users; You must
                keep your username and passwords confidential and not share it
                withothers; and All information given must be genuine and true to
                your best knowledge. TheCompany shall not be held responsible in
                case of any fraudulent information givenat the time of
                registration for usingNushift connect app. Any violation of the
                guidelines as mention in this Clause 5.1 may lead to
                therestriction, suspension or termination of your account with
                Company, at the solediscretion of the Company.
              </li>
            </ul>
          </li>
  
          <!-- 6th Point -->
          <li>
            <p class="para-bold">International Transfer Of Data.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                In the process of providing Services to you, Company may transfer
                the Personal Data andother information that it collects to
                affiliated entities or other third parties across borders,and from
                your country or jurisdiction to other countries or jurisdictions
                around the world.You understand, acknowledge and consent to
                transferring information and permitting thetransfer of
                information, including Personal Data, to a country and
                jurisdiction that does nothave the same data protection laws as
                your jurisdiction. Your hereby consent to the transferof your
                information across countries or jurisdictions around the world
              </li>
            </ul>
          </li>
  
          <!-- 7th Point -->
          <li>
            <p class="para-bold">Governing Law.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                The usage of the Services is exclusively governed by the laws
                ofIndiaand the jurisdictionof the competent courts in Bangalore.
                The Company expressly disclaims any impliedwarranties or
                liabilities imputed by the laws of any other jurisdiction
              </li>
            </ul>
          </li>
  
          <!-- 8th Point -->
          <li>
            <p class="para-bold">Modifications.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                Any changes in this Policy by the Company shall be posted on the
                Website to reflectchanges in its Privacy Policy so that you are
                always aware of what information Companycollects, uses, and under
                what circumstances, if any, Company may disclose it. You should
                periodically review the above mentioned page for the latest
                information on Company’sprivacy practices. Once posted, those
                changes are effective immediately. Continued access oruse of
                Nushift connect app constitutes your acceptance of the changes and
                the amendedPolicy
              </li>
            </ul>
          </li>
          <!-- 9th Point -->
          <li>
            <p class="para-bold">Third Party Application Stores.</p>
            <ul class="lower-rom-list">
              <li class="bullet">
                The App can be downloaded from third party application stores
                including Google Play, andApple App Store. The App may contain
                links to other third-party websites that may collectthe Personal
                Data. The User agrees to comply with all third party privacy
                policies, if any,applicable to the use of such applications or its
                services. The Company is not responsiblefor the User’s use of any
                third party product and/or service including the terms
                andconditions, privacy practices or the content of those linked
                websites or such third partyproduct and/or service or violation
                and infringement of any third party rights, policies,terms and
                conditions in connection with access, download or installation of
                such mobileapplications
              </li>
            </ul>
          </li>
          <!-- 10th Point -->
          <li>
            <p class="para-bold">Advertisements.</p>
            <ul class="lower-rom-list">
              <li>
                Company may use third-party advertising companies to serve
                advertisements when youcontact it. These companies may use
                information (not including name, address, emailaddress, or
                telephone number of the user) about your visits to Nushift Connect
                App andother websites in order to provide advertisements about
                goods and services of interest toyou.
              </li>
            </ul>
          </li>
  
          <!-- 11th Point -->
          <li>
            <p class="para-bold">Questions/Grievances</p>
            <ul class="lower-rom-list">
              <li>
                Any grievances or questions regarding this statement should be
                directed to the followinge-mail address: <a href="mailto:support.nc@nushift.in">support.nc@nushift.in</a> 
              </li>
              <!-- <li>
                &nbsp;
              </li> -->
              <!-- <li>
                &nbsp;
              </li> -->
            </ul>
          </li>
  
          
        </ol>
      </div>
    </div>
  </div>
  