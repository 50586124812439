import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
// import { NavbarService } from 'src/app/services/navbar.service';
// import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;
  notifications = [];
  encodedJsonString: string = '';
  notification_Type: string = 'ALL';
  @Input() isOpen: boolean = false;
  tabIndex: any = 0;

  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {


    this.getNotificationsList('ALL');
  }
  tabClick(event: any) {
    // To detect tab changing event(referrals,events,invites)
    // this.tabIndex = event;
    switch (event) {
      case 0:
        this.getNotificationsList('ALL');
        break;
      case 1:
        this.getNotificationsList('REF');
        break;
      case 2:
        this.getNotificationsList('NTW');
        break;
      case 3:
        this.getNotificationsList('INV');
        break;
      default:
        break;
    }
  }
  getNotificationsList(type) {
    this.authService
      .getNotificationList({
        uid: this.authService.userValue.uid,
        filterBy: type,
      })
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.notifications = res.data;
          } else {
            this.notifications = [];
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }



  onClear() {
    this.isOpen = false;
  }

  notificationClick(notification: any) {
    let notificationType = notification.pType
      ? notification.pType.toLowerCase()
      : notification.ntype
      ? notification.ntype.toLowerCase()
      : '';
    switch (notificationType) {
      case 'ref_view':
      
        this.encodedJsonString = encodeURIComponent(
          JSON.stringify(notification)
        );
        this.router.navigate(['/refer'], {
          queryParams: {
            from: 'notifications',
            notification: this.encodedJsonString,
          },
        });
        break;
      case 'fedpst':
      case 'feedlike':
      case 'feedcomment':
      case 'feedshare':
       
        this.encodedJsonString = encodeURIComponent(
          JSON.stringify(notification)
        );
        this.router.navigate(['/feed'], {
          queryParams: {
            from: 'notifications',
            notification: this.encodedJsonString,
          },
        });
        break;
      case 'event_view':
       
        this.router.navigate(['events/view-event'], {
          queryParams: {
            eventId: notification.idref,
            isHost: notification?.is_host,
            eventType: notification?.type?.toLowerCase(),
          },
        });
        break;
      case 'network_view':
      case 'profile_view':
       
        notification['uid'] = notification.sender_id;
        this.encodedJsonString = encodeURIComponent(
          JSON.stringify({
            viewId: notification.sender_id,
            connectionDetails: notification,
          })
        );
        this.router.navigate(['/profile'], {
          queryParams: { profile: this.encodedJsonString },
        });
        break;
      case 'rcmnd_snt':
       
        notification['uid'] = notification.sender_id;
        this.encodedJsonString = encodeURIComponent(
          JSON.stringify({
            userId: notification.sender_id,
            notification: notification,
          })
        );
        this.router.navigate(['/profile/recommendations'], {
          queryParams: { notification: this.encodedJsonString },
        });
        break;
    }
  }

  onNotificationClear(notification: any) {
    this.authService
      .clearNotification({
        uid: this.authService.userValue.uid,
        notification_id: notification.id,
      })
      .subscribe(
        (res) => {
          if (res.status) {
            this.openSnackBar(res.message, '');
            this.getNotificationsList('');
          } else {
            this.openSnackBar(res.message, '');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
