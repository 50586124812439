import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { JobsService } from '../../services/jobs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-manage-jobs',
  templateUrl: './my-manage-jobs.component.html',
  styleUrls: ['./my-manage-jobs.component.scss'],
})
export class MyManageJobsComponent implements OnInit {
  recommendedJobsList: any[] = [];
  defaultImage = './assets/img/nushiftconnect_logo.svg';
  @Input() tabName: string = '';

  @ViewChild('firstButton') firstButton: ElementRef;
  @ViewChild('secondButton') secondButton: ElementRef;
  savedJobs: any;
  comingFrom: any = '';
  appliedJobs: any;
  showJobPreview: boolean = false;
  jobDetails: any;
  activeJobs: any;
  closedJobs: any;
  jobType: any;
  applicantsList: any;
  firstButtonText: any = '';
  secondButtonText: any = '';y
  showJobApplyPreview: boolean = false;
  uploadedResume: File | any;
  applicationForm: FormGroup;
  // private fb: FormBuilder;
  applyJobForm = false;
  selectFirstButton : boolean = true;
  selectSecondButton : boolean = false;


  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private jobsService: JobsService,
    private _snackBar: MatSnackBar,
    private apiEndPointService: ApiEndpointsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {

    console.log('my-manage-job-component loaded', this.tabName);

    if (this.tabName == 'my-jobs') {
      this.selectFirstButton  = true;
      this.selectSecondButton = false;
      this.getSavedJobs('saved');
    } else {
      this.getSavedJobs('active');
    }

    this.applicationForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
      resume: ['', Validators.required]
    })

  }

  applyJob(job: any) {
    this.showJobPreview = false
    this.showJobApplyPreview = true;
  }
  async viewApplicants(jobDetails) {
    let payload = {
      uid: this.authService.userValue.uid,
      jobID: jobDetails.job_id
    }
    this.spinner.show();
    (await this.jobsService.getApplicantsList(payload)).subscribe((res: any) => {
      this.spinner.hide();
      let response;
      if (res.status) {
        console.log(res);
        this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, res.data).then((data) => {
          response = data;
          console.log('Applicants List:-');
          this.applicantsList = JSON.parse(response.replace(/'/g, '"').replace(/: None/g, ': "None"'));
          console.log(this.applicantsList);
        });
        this.openSnackBar(res.message, '');
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar(error, '');
    });
  }
  uploadResume(files: FileList): void {
    // if (event.target.files.length > 0) {
      this.uploadedResume = files.item(0);

      console.log(this.uploadResume);
      this.applicationForm.patchValue({
        resume: files.item(0),
      });
    // }
  }
  submitJob(job: any) {

    this.applyJobForm = true;
    if (this.applicationForm.invalid) {
      return;
    }
else
{

    let job_apply_payload = {
      uid: this.authService.userValue.uid,
      jobID: this.jobDetails.job_id,
      email: this.applicationForm.get('emailAddress').value,
      mobile: this.applicationForm.get('phoneNumber').value,
      country_code: "91"
    }

    console.log(job_apply_payload,"Job apply payload");
    this.uploadedResume['resume'] = this.uploadedResume.name;
    this.jobsService.applyJob(job_apply_payload, this.uploadedResume).subscribe((result) => {
      console.log(result);
      if (result.status) {
        this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, result.data).then((data) => {
          let response = data;
          console.log(response);
          this.applyJobForm = false;
        });
        this.openSnackBar(result.message, "");
      }
    })
    console.log(this.applicationForm.value);
  }
}
  // onJobClick(job: any) {
  //   console.log(job.job_tle);
  //   this.showJobPreview = true;
  // }
  jobPreview(jobType, jobDetails) {
    console.log(jobType,jobDetails,"jobType,jobDetails.");
    this.jobDetails = jobDetails;
    this.jobType = jobType;
    this.showJobPreview = true;
    this.applicantsList = [];
  }

  getFirstButtonText() {
    this.firstButtonText = this.firstButton.nativeElement.textContent.trim();
    this.selectFirstButton  = true;
    this.selectSecondButton = false;
    //alert(this.firstButtonText);
    if (this.tabName == 'my-jobs') {
      this.comingFrom = 'saved';
      this.showJobPreview = false;
      this.applyJobForm = false;
      this.getSavedJobs(this.comingFrom);
    } else {
      this.comingFrom = 'active';
      this.showJobPreview = false;
      this.applyJobForm = false;
      this.getSavedJobs(this.comingFrom);
    }
  }
  getSecondButtonText() {
    this.secondButtonText = this.secondButton.nativeElement.textContent.trim();
    this.selectFirstButton  = false;
    this.selectSecondButton = true;
    // alert(this.secondButtonText);
    //alert(this.firstButtonText);
    if (this.tabName == 'my-jobs') {
      this.comingFrom = 'applied';
      this.showJobPreview = false;
      this.applyJobForm = false;
      this.getSavedJobs(this.comingFrom);
    } else {
      this.comingFrom = 'closed';
      this.showJobPreview = false;
      this.applyJobForm = false;
      this.getSavedJobs(this.comingFrom);
    }
  }

  async saveJob(job: any) {
    job.ap_sts = "saved";
     // Method to get my groups
     const formData = {
      uid: this.authService.userValue.uid,
      jobID: job.job_id
    };

    console.log(formData);

    // this.spinner.show();
    (await this.jobsService.saveJob(formData)).subscribe(
      (res: any) => {
        // this.spinner.hide();
        // this.myjobsList = [];
        let response;
        if (res.status) {
          this.spinner.hide();
          console.log(res);
          this.apiEndPointService
            .decodeAesToken(this.authService.userValue.uid, res.data)
            .then((data) => {
              response = data;
              // this.recommendedJobsList = JSON.parse(
              //   response.replace(/'/g, '"').replace(/: None/g, ': "None"')
              // );
              console.log(response);
            });
          this.openSnackBar(res.message, '');
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        // this.spinner.hide();
        this.spinner.hide();
        // this.openSnackBar(error, '');
        this.openSnackBar('Something went wrong! Please try again', '');
      }
    );
  }

  async unsaveJob(job: any) {
    job.ap_sts = "";
    // Method to get my groups
    const formData = {
     uid: this.authService.userValue.uid,
     jobID: job.job_id
   };

   console.log(formData);

   // this.spinner.show();
   (await this.jobsService.unsaveJob(formData)).subscribe(
     (res: any) => {
       // this.spinner.hide();
       // this.myjobsList = [];
       let response;
       if (res.status) {
         this.spinner.hide();
         console.log(res);
         this.apiEndPointService
           .decodeAesToken(this.authService.userValue.uid, res.data)
           .then((data) => {
             response = data;
             // this.recommendedJobsList = JSON.parse(
             //   response.replace(/'/g, '"').replace(/: None/g, ': "None"')
             // );
             console.log(response);
           });
         this.openSnackBar(res.message, '');
       } else {
         this.spinner.hide();
       }
     },
     (error) => {
       // this.spinner.hide();
       this.spinner.hide();
       // this.openSnackBar(error, '');
       this.openSnackBar('Something went wrong! Please try again', '');
     }
   );
 }
  openSnackBar(message: string, action: string): void {
    // Method to show alert when any api calls
    this._snackBar.open(message, action, { duration: 3000 });
  }

  async getSavedJobs(type) {
    this.comingFrom = type;
    if (type == 'saved') {
      let payload = {
        uid: this.authService.userValue.uid,
      };
      this.spinner.show();
      (await this.jobsService.getSavedJobs(payload)).subscribe(
        (res: any) => {
          let response;
          if (res.status) {
            this.spinner.hide();
            console.log(res);
            this.apiEndPointService
              .decodeAesToken(this.authService.userValue.uid, res.data)
              .then((data) => {
                response = data;
                if (data) {
                  console.log(data.length); // Now it's safe to access length
                  console.log('saved Jobs:-');
                  this.savedJobs = JSON.parse(
                    response.replace(/'/g, '"').replace(/: None/g, ': "None"')
                  );
                  this.savedJobs.forEach((job) => {
                    if (job.cd) {
                      let startDate = new Date(job.cd).getTime();
                      let endDate = new Date().getTime();
                      let timeDifference = endDate - startDate;
                      let days = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      let hours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      let minutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );
                      let seconds = Math.floor(
                        (timeDifference % (1000 * 60)) / 1000
                      );
                      console.log(
                        days + ' Days ',
                        hours +
                          ' Hours ' +
                          minutes +
                          ' Minutes ' +
                          seconds +
                          ' Seconds '
                      );
                      job.cd = days
                        ? days + ' Days '
                        : hours
                        ? hours + ' Hours '
                        : minutes
                        ? minutes + ' Minutes '
                        : seconds
                        ? seconds + ' Seconds '
                        : '';
                    }
                  });

                }
  
              });
            this.openSnackBar(res.message, '');
          } else {
            this.spinner.hide();
            this.savedJobs = [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.openSnackBar(error, '');
        }
      );
    }
     else if (type == 'applied') 
      {
      let payload = {
        uid: this.authService.userValue.uid,
      };
      this.spinner.show();
      (await this.jobsService.getAppliedJobs(payload)).subscribe(
        (res: any) => {
          let response;
          if (res.status) {
            this.spinner.hide();
            console.log(res);
            this.apiEndPointService
              .decodeAesToken(this.authService.userValue.uid, res.data)
              .then((data) => {
                response = data;
                if (data) {
                  console.log(data.length); // Now it's safe to access length
                  console.log('Applied Jobs:-');
                  this.appliedJobs = JSON.parse(
                    response.replace(/'/g, '"').replace(/: None/g, ': "None"')
                  );
                  this.appliedJobs.forEach((job) => {
                    if (job.cd) {
                      let startDate = new Date(job.cd).getTime();
                      let endDate = new Date().getTime();
                      let timeDifference = endDate - startDate;
                      let days = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      let hours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      let minutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );
                      let seconds = Math.floor(
                        (timeDifference % (1000 * 60)) / 1000
                      );
                      console.log(
                        days + ' Days ',
                        hours +
                          ' Hours ' +
                          minutes +
                          ' Minutes ' +
                          seconds +
                          ' Seconds '
                      );
                      job.cd = days
                        ? days + ' Days '
                        : hours
                        ? hours + ' Hours '
                        : minutes
                        ? minutes + ' Minutes '
                        : seconds
                        ? seconds + ' Seconds '
                        : '';
                    }
                  });

                }
  
              });
            this.openSnackBar(res.message, '');
          } else {
            this.spinner.hide();
            this.appliedJobs = [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.openSnackBar(error, '');
        }
      );
    } 
    else if (type == 'active') 
      {
      let payload = {
        uid: this.authService.userValue.uid,
      };
      this.spinner.show();
      (await this.jobsService.getActiveJobs(payload)).subscribe(
        (res: any) => {
          let response;
          if (res.status) {
            this.spinner.hide();
            console.log(res);
            this.apiEndPointService
              .decodeAesToken(this.authService.userValue.uid, res.data)
              .then((data) => {
                response = data;
                if (data) {
                  console.log(data.length); // Now it's safe to access length
                  console.log('Active Jobs:-');
                  this.activeJobs = JSON.parse(
                    response.replace(/'/g, '"').replace(/: None/g, ': "None"')
                  );
                  this.activeJobs.forEach((job) => {
                    if (job.cd) {
                      let startDate = new Date(job.cd).getTime();
                      let endDate = new Date().getTime();
                      let timeDifference = endDate - startDate;
                      let days = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      let hours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      let minutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );
                      let seconds = Math.floor(
                        (timeDifference % (1000 * 60)) / 1000
                      );
                      console.log(
                        days + ' Days ',
                        hours +
                          ' Hours ' +
                          minutes +
                          ' Minutes ' +
                          seconds +
                          ' Seconds '
                      );
                      job.cd = days
                        ? days + ' Days '
                        : hours
                        ? hours + ' Hours '
                        : minutes
                        ? minutes + ' Minutes '
                        : seconds
                        ? seconds + ' Seconds '
                        : '';
                    }
                  });

                }
  
              });
            this.openSnackBar(res.message, '');
          } else {
            this.spinner.hide();
            this.activeJobs = [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.openSnackBar(error, '');
        }
      );
    } 
    else if (type == 'closed') 
      {
      let payload = {
        uid: this.authService.userValue.uid,
      };
      this.spinner.show();
      (await this.jobsService.getClosedJobs(payload)).subscribe(
        (res: any) => {
          let response;
          if (res.status) {
            this.spinner.hide();
            console.log(res);
            this.apiEndPointService
              .decodeAesToken(this.authService.userValue.uid, res.data)
              .then((data) => {
                response = data;
                if (data) {
                  console.log(data.length); // Now it's safe to access length
                  console.log('closed Jobs:-');
                  this.savedJobs = JSON.parse(
                    response.replace(/'/g, '"').replace(/: None/g, ': "None"')
                  );
                  this.closedJobs.forEach((job) => {
                    if (job.cd) {
                      let startDate = new Date(job.cd).getTime();
                      let endDate = new Date().getTime();
                      let timeDifference = endDate - startDate;
                      let days = Math.floor(
                        timeDifference / (1000 * 60 * 60 * 24)
                      );
                      let hours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) /
                          (1000 * 60 * 60)
                      );
                      let minutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                      );
                      let seconds = Math.floor(
                        (timeDifference % (1000 * 60)) / 1000
                      );
                      console.log(
                        days + ' Days ',
                        hours +
                          ' Hours ' +
                          minutes +
                          ' Minutes ' +
                          seconds +
                          ' Seconds '
                      );
                      job.cd = days
                        ? days + ' Days '
                        : hours
                        ? hours + ' Hours '
                        : minutes
                        ? minutes + ' Minutes '
                        : seconds
                        ? seconds + ' Seconds '
                        : '';
                    }
                  });

                }
  
              });
            this.openSnackBar(res.message, '');
          } else {
            this.spinner.hide();
            this.closedJobs = [];
          }
        },
        (error) => {
          this.spinner.hide();
          this.openSnackBar(error, '');
        }
      );
    }
  }

}
