<div class="container-fluid row pb-3">
    <div class="col-md-5 get-invited-background">
        <div class="get-invited-img"></div>
    </div>
    <div class="col-md-7 d-flex">
      <div class="container details-container align-self-center">
          <h1 class="text-left">Get Invited</h1>
          <form [formGroup]="inviteForm" class="d-flex flex-column w-100 align-items-center justify-content-between">

            <!-- First Name Field -->
            <div class="w-100 pt-3">
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <!-- <mat-label>First Name</mat-label> -->

                <mat-label style="color: black;"> <strong>First Name</strong> </mat-label>

                <input matInput formControlName="firstName" placeholder="First Name" required/>
                <mat-error *ngIf="inviteForm.get('firstName').touched && inviteForm.get('firstName').errors?.required">First Name is Required</mat-error>
              </mat-form-field>
            </div>

            <!-- Last Name Field -->
            <div class="w-100 pt-3">
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <!-- <mat-label>Last Name</mat-label> -->
                <mat-label style="color: black;"> <strong>Last Name</strong> </mat-label>

                <input matInput formControlName="lastName" placeholder="Last Name" required/>
                <mat-error *ngIf="inviteForm.get('lastName').touched && inviteForm.get('lastName').errors?.required">Last Name is Required</mat-error>
              </mat-form-field>
            </div>

            <!-- Email Field -->
            <div class="w-100 pt-3">
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <!-- <mat-label>Email</mat-label> -->
                <mat-label style="color: black;"> <strong>Email</strong> </mat-label>

                <input matInput formControlName="email" placeholder="Email" required/>
                <mat-error *ngIf="inviteForm.get('email').touched && inviteForm.get('email').errors?.required">Email is Required</mat-error>
                <mat-error *ngIf="inviteForm.get('email').touched && inviteForm.get('email').errors?.pattern">Email is Invalid</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Phone Number Field -->
            <div class="w-100 pt-3">
              <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                <!-- <mat-label>Phone Number</mat-label> -->
                <mat-label style="color: black;"> <strong>Phone Number</strong> </mat-label>

                <input matInput formControlName="mobile" placeholder="Phone Number" required maxlength="10"/>
                <mat-error *ngIf="inviteForm.get('mobile').touched && inviteForm.get('mobile').errors?.required">Phone Number is Required</mat-error>
              </mat-form-field>
            </div>

            <!-- Submit Button -->
            <button mat-raised-button class="button-submit rounded w-100" (click)="invite()" *ngIf="showSpinner === false && isOtpVerified === false" [disabled]="inviteForm.invalid">Submit</button>
          </form>
          <h4 *ngIf="mobileStatusMessage" class="text-success">{{ mobileStatusMessage }}</h4>
      </div>
    </div>
</div>

<!-- Showing Spinner in UI -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="ball-atom" [fullScreen]="false">
  <b style="color: white"> Loading... </b>
</ngx-spinner>
