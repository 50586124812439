<!-- <div class="container-fluid notifications-main-container position-absolute">
    <p>Notifications component!</p>
</div> -->

<div [ngClass]="{ 'my-drawer': true, open: isOpen }" style="overflow: auto">
  <div class="d-flex align-items-start">
    <h1 class="notification-heading col-10">Notification</h1>
    <!-- <button mat-button class="mr-2" mat-dialog-close>Cancel</button> -->
    <mat-icon class="float-right col-2 p-2" (click)="onClear()"> highlight_off</mat-icon>
  </div>

  <!-- Tabs List ["ALL", "REF", "NTW", "APT", "INV"]-->

  <mat-tab-group
    fitInkBarToContent
    mat-align-tabs="start"
    (selectedTabChange)="tabClick($event.index)"
    [(selectedIndex)]="tabIndex"
  >
    <mat-tab label="Recent"></mat-tab>
    <mat-tab label="Referral"></mat-tab>
    <mat-tab label="Networks"></mat-tab>
    <mat-tab label="Invites"></mat-tab>
  </mat-tab-group>

  <!-- Notofications List -->

  <div class="row">
    <ng-container *ngIf="notifications.length; else noNotifications">
      <div *ngFor="let notification of notifications">
        <div class="row">
          <div class="col-2">
            <img
              src="assets/img/notifications/followers.png"
              alt="../../../assets/img/updated-nushift-logo.png"
              height="48px"
              width="48px"
              class="m-2"
            />
          </div>
          <div class="col-10">
            <label class="notification-name">{{ notification.title }}</label>
            <label
              class="notification-desc"
              (click)="notificationClick(notification)"
              >{{ notification.msg }}</label
            >
            <label class="align-self-end mr-2 notification-time">{{
              notification.cd
            }}</label>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noNotifications>
      <h4
        class="d-flex justify-content-center align-items-center m-3 w-100"
        style="color: red"
      >
        No Notifications
      </h4>
    </ng-template>
  </div>
</div>
