import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // return true;

    const uid = this.authService.userValue.uid; // Check for UID in localStorage
    if (uid) {
      return true; // UID found, allow access
    } else {
      // this.router.navigate(['']); // UID not found, redirect to No Data Found page
      this.router.navigate(['/landing']); // UID not found, redirect to No Data Found page
      return false; // Prevent access
    }
  }
  
}
