import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavbarService {
  footerFixed: boolean = false;
  footerVisible: boolean = false;
  navbarVisible: boolean = false;

  constructor() {
    this.navbarVisible = false;
    this.footerVisible = false;
    this.footerFixed = false;
  }

  navbarHide() {
    this.navbarVisible = false;
  }

  navbarShow() {
    this.navbarVisible = true;
  }

  navbarToggle() {
    this.navbarVisible = !this.navbarVisible;
  }

  footerHide() {
    this.footerVisible = false;
  }

  footerShow() {
    this.footerVisible = true;
  }

  footerToggle() {
    this.footerVisible = !this.footerVisible;
  }

  setfooterFixed() {
    this.footerFixed = true;
  }
  setfooterNotFixed() {
    this.footerFixed = false;
  }
}
