import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.scss']
})
export class EnquiriesComponent implements OnInit {
  user: User;
  userList$: Observable<User[]>;
  userList: User[] = [];
  subscription: Subscription;
  toId: string;
  enquiryForm = new FormGroup({
    uid: new FormControl('', Validators.required),
    sendTo: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl(''),
    description: new FormControl('', Validators.required),
    regarding: new FormControl('', Validators.required),
    to_id: new FormControl(''),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
    ]),
  });
  enquiriesList: any = [];

  constructor(
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EnquiriesComponent>,
    private apiEndPointService: ApiEndpointsService,
    private moreScreenService: MoreScreenService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.userValue;
    this.enquiryForm.patchValue({
      uid: this.user.uid,
      name: this.user.name,
      email: this.user.email,
      phone: this.user.mob,
    });
    this.getEnquiryService();
    this.subscription = this.enquiryForm.get('sendTo').valueChanges.subscribe((value: any) => this.getUserList());
    this.userList$ = this.enquiryForm.get('to_id').valueChanges.pipe(startWith(''), map((value) => this._filter(value)));
  }

  ngDestroy() {
    this.subscription.unsubscribe();
  }
  async onSubmit() {  // Calling save api on clicking of SUBMIT button
    this.spinner.show();
    let formData = this.enquiryForm.value;
    formData.uid = this.user.uid;
    formData.to_id = this.toId;
    let payload = {
      description: this.enquiryForm.get('description').value,
      email: this.enquiryForm.get('email').value,
      name: this.enquiryForm.get('name').value,
      phone: this.enquiryForm.get('phone').value,
      regarding: this.enquiryForm.get('regarding').value,
      uid: this.authService.userValue.uid
    };
    (await this.moreScreenService.sendAnEnquiry(payload)).subscribe((res) => {
      this.spinner.hide();
      console.log(res);
      if (res.status && res.data) {
        this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then(async (data) => {
          console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')));
        });
      }
      this.openSnackBar(res.message, '');
      if (res.status) {
        this.spinner.hide();
        this.dialogRef.close(res);
      } else if (!res.status) {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }

  getUserList() {  // To get the dropdown for select user(to_id) field
    let queryParameters = {
      uid: this.user.uid,
      sendTo: this.enquiryForm.get('sendTo').value,
    };
    this.moreScreenService.getUserListBasedOnSearch(queryParameters)
      .subscribe((res) => {
        if (res.status) this.userList = res.data;
        else this.openSnackBar(res.message, '');
      });
  }

  getEnquiryService() {   // To get the dropdown for enquiry related to(regarding) field
    this.moreScreenService.getEnquiryServices().subscribe((res) => {
      console.log(res);
        if (res.status) this.enquiriesList = res.data;
        else this.openSnackBar(res.message, '');
      });
  }

  selectUser(id: string) {  //  detect the value changes on select user(to_id) field
    this.toId = id;
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    let uList = [...this.userList];
    return uList.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  openSnackBar(message: string, action: string): void {  //To show the alert messages when api fails/gets success
    this._snackBar.open(message, action, { duration: 3000 });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
