import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrls: ['./apply-job.component.scss']
})
export class ApplyJobComponent implements OnInit {


  applicationForm: FormGroup;
  uploadedResume: File | any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private jobsService: JobsService) { }

  ngOnInit(): void {
    // console.log(this.data);
    this.createApplicationForm();
  }

  createApplicationForm() {
    this.applicationForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
      resume: ['', Validators.required]
    })
  }

  uploadResume(files: FileList): void {
    // if (event.target.files.length > 0) {
      this.uploadedResume = files.item(0);
      this.applicationForm.patchValue({
        resume: files.item(0),
      });
    // }
  }

  closeDialog() {
    // this.dialogRef.close(null);
  }

  submitJob() {
    let payload = {
      uid: this.authService.userValue.uid,
      // jobID: this.data.jobDetails.job_id,
      email: this.applicationForm.get('emailAddress').value,
      mobile: this.applicationForm.get('phoneNumber').value,
      country_code: "91"
    }
    // this.uploadedResume['filename'] = this.uploadedResume.name;
    this.jobsService.applyJob(payload, this.uploadedResume).subscribe((result) => {
      if (result.status == true) {
        // this.dialogRef.close({res: result, comingFrom: this.data.jobDetails.comingFrom});
      }
    })
    // console.log(this.applicationForm.value);
  }
}
