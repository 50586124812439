<!-- <h3 mat-dialog-title>Deactivate Account</h3> -->
<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">Deactivate Account</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="Nushift">
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <ng-container>
    <div class="d-flex justify-content-center flex-column">
      <form class="d-flex flex-wrap ">
        <div class="w-100 mb-2 mt-3">
          <mat-radio-group aria-label="Select an option" class="d-flex flex-column">
            <mat-radio-button class="mr-2" value="privacy"><small>Privacy Concerns</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="notInterested"><small>Not interested</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="irrelevant"><small>Irrelevant content</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="others"><small>Others</small></mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" floatLabel='always' class=" w-100 mt-2">
          <mat-label style="color: black;"><strong> Reason </strong>  </mat-label>

          <textarea matInput placeholder="Any specific reason?"></textarea>
        </mat-form-field>
      </form>
      <p class="">
        <!-- <button mat-button class="button-cancel mr-2" mat-dialog-close>Cancel</button> -->
        <button mat-button class="btn-save " (click)="onSubmit()"> Deactivate</button>
      </p>
    </div>
  </ng-container>
</mat-dialog-content>
