import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-rate-us',
  templateUrl: './rate-us.component.html',
  styleUrls: ['./rate-us.component.css']
})
export class RateUsComponent implements OnInit {

  user: User;
  rateUsForm = new FormGroup({
    uid: new FormControl('', Validators.required),
    feedback: new FormControl('5', [Validators.required, Validators.email]),
    description: new FormControl('', Validators.required),
    from: new FormControl('D', [Validators.required]),
  });

  constructor(private _snackBar: MatSnackBar, private spinner: NgxSpinnerService,
    public authService: AuthService, public dialogRef: MatDialogRef<RateUsComponent>, private moreScreenService: MoreScreenService) { }


  ngOnInit(): void {
    this.user = this.authService.userValue;
    this.rateUsForm.patchValue({ uid: this.user.uid });
  }

  onSubmit() {
    this.spinner.show();
    let formData = this.rateUsForm.value;
    formData['uid'] = this.authService.userValue.uid;
    this.moreScreenService.rateUs(formData).subscribe((res) => {
      this.spinner.hide();
      console.log(res);
      if (res.status) this.dialogRef.close();
      this.openSnackBar(res.message, "");
    })
  }

  setFeedBack(fb: string) {
    this.rateUsForm.value.feedback = fb;
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, { duration: 3000 });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
