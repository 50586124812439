<div class="container h-100">
    <h2 class="text-center m-3">Forgot Password</h2>
    <form class="m-auto w-50 d-flex flex-column" [formGroup]="forgotForm">
      <!-- <app-generic-input class="px-2" [placeholder]="'Email/Phone.No'" [type]="'text'" [label]="'Email/Phone.No'"
        (onComponentReady)="addChild('email', $event)" [required]="true" [validators]='emailValidators'>
      </app-generic-input> -->
  
      <!-- EMAIL/MOBILE NUMBER FIELD -->
      <div class="w-100">
        <mat-form-field appearance="" floatLabel="always" class="w-100">
          <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Email/Phone
            number <span class="text-danger">*</span></mat-label>
          <input matInput formControlName="email" class="mt-3" [required]="true" />
        </mat-form-field>
      </div>
  
      <!-- USER TYPE FIELD -->
      <!-- <mat-form-field floatLabel='always' appearance="standard" class="w-100">
        <mat-label class="text-color">User Type <span class="text-danger">*</span></mat-label>
        <mat-select formControlName="user_type">
          <mat-option value="D">Doctor</mat-option>
          <mat-option value="H">Hospital</mat-option>
          <mat-option value="L">Diagnostic Center</mat-option>
        </mat-select>
      </mat-form-field> -->
  
      <!-- OTP FIELD -->
      <div class="w-100 mt-2" *ngIf="showOtpField">
        <mat-form-field appearance="" floatLabel="always" class="w-100">
          <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Enter OTP<span
              class="text-danger">*</span></mat-label>
          <input matInput formControlName="otp" class="mt-3" [required]="true" />
        </mat-form-field>
      </div>
  
      <p class="mt-2">New password should not match with the previous password.</p>
  
      <!-- NEW PASSWORD FIELD -->
      <div class="w-100 mt-2" *ngIf="verifiedOtpField">
        <mat-form-field appearance="" floatLabel="always" class="w-100">
          <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Enter New
            password<span class="text-danger">*</span></mat-label>
          <input matInput formControlName="new_password" class="mt-3" [type]="hide ? 'password' : 'text'" />
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
  
      <!-- CONFIRM PASSWORD FIELD -->
      <div class="w-100 mt-2" *ngIf="verifiedOtpField">
        <mat-form-field appearance="" floatLabel="always" class="w-100">
          <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Enter Confirm
            password<span class="text-danger">*</span></mat-label>
            <input matInput formControlName="confirm_password" class="mt-3" [type]="hide_two ? 'password' : 'text'" />
            <mat-icon matSuffix (click)="hide_two = !hide_two">{{hide_two ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
      </div>
  
      <!-- To Send OTP -->
      <button *ngIf="!showOtpField" class="btn btn-sm ns-bg-blue text-white mt-4" (click)="generateOTP('')">
        Send OTP
      </button>
  
      <!-- To Validate OTP -->
      <button *ngIf="showOtpField && !verifiedOtpField" class="btn btn-sm ns-bg-blue text-white"
        (click)="verifyOtp(this.forgotForm.get('otp').value)">
        Validate OTP
      </button>
  
      <!-- To Update Password Credentials -->
      <button *ngIf="verifiedOtpField" class="btn btn-sm ns-bg-blue text-white" (click)="updatePassword()">
        Submit
      </button>
    </form>
  </div>
  
  <!-- SHOWING LOADER ON THE SCREEN -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
    <b style="color: white"> Loading... </b>
  </ngx-spinner>