<!-- <h3 mat-dialog-title>Give Feedback</h3> -->

<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">Give Feedback</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="../../../assets/img/updated-nushift-logo.png">
  </button>
</div>
<h4 mat-dialog-title>Please provide feedback</h4>
<mat-dialog-content class="mat-typography">
  <ng-container>
    <div class="d-flex justify-content-center flex-column">
      <form class="d-flex flex-wrap " [formGroup]="rateUsForm">
        <div class="w-100 mb-2 mt-3" id="smileys">
          <!-- <mat-radio-group aria-label="Select an option" class="d-flex flex-column">
            <mat-radio-button class="mr-2" value="privacy"><small>Privacy Concerns</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="notInterested"><small>Not interested</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="irrelevant"><small>Irrelevant content</small></mat-radio-button>
            <mat-radio-button class="mr-2" value="others"><small>Others</small></mat-radio-button>
          </mat-radio-group> -->
          <mat-radio-group aria-label="Select an option" class="d-flex" formControlName="feedback">
            <input type="radio" name="smiley" value="1" class="good" (click)="setFeedBack('5')">
            <input type="radio" name="smiley" value="3" class="average" (click)="setFeedBack('3')">
            <input type="radio" name="smiley" value="5" class="bad" (click)="setFeedBack('1')">
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
          <mat-label style="color: black;"> <strong> Comments </strong> </mat-label>
          <textarea formControlName="description" matInput placeholder="Any comments?"></textarea>
        </mat-form-field>
        
      </form>
      <p class="">
        <!-- <button mat-button class="button-cancel mr-2" mat-dialog-close (click)="closeDialog()">Cancel</button> -->
        <button mat-button class="btn-save " (click)="onSubmit()"> Submit</button>
      </p>
    </div>
  </ng-container>
</mat-dialog-content>
