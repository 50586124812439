<div class="d-flex mb-3">
  <h3 mat-dialog-title class="m-2 fw-bold">Change Password</h3>
  <button class="button-close ms-auto p-2" mat-dialog-close>
    <img src="../../../assets/img/close.png" alt="Nushift">
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="d-flex justify-content-center flex-column">
    <form [formGroup]="changePassForm" class="d-flex flex-wrap ">

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"><strong>Email</strong>  </mat-label>
        <input readonly formControlName="email" matInput placeholder="Email" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong> Old Password </strong> </mat-label>
        <input formControlName="old_password" matInput placeholder="Old Password" [type]="ohide ? 'password' : 'text'"
          required autocomplete="off">
        <mat-icon matSuffix (click)="ohide = !ohide">{{ohide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="changePassForm.hasError('required', 'old_password')">
          Please enter your old password
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"><strong>New Password</strong>  </mat-label>
        <input formControlName="new_password" matInput placeholder="New Password" [type]="nhide ? 'password' : 'text'"
          required autocomplete="off">
        <mat-icon matSuffix (click)="nhide = !nhide">{{nhide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="changePassForm.hasError('required', 'new_password')">
          Please enter your new password
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
        <mat-label style="color: black;"> <strong>Confirm Password</strong> </mat-label>
        <input formControlName="confirm_password" matInput placeholder="Confirm Password"
          [type]="chide ? 'password' : 'text'" [errorStateMatcher]="matcher" required autocomplete="off">
        <mat-icon matSuffix (click)="chide = !chide">{{chide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="changePassForm.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </mat-form-field>

    </form>
    <p class="">
      <!-- <button mat-button class="button-cancel mr-2" mat-dialog-close>Cancel</button> -->
      <button mat-button class="btn-save" [disabled]="changePassForm.invalid" (click)="onSubmit()">
        Submit</button>
    </p>
  </div>
</mat-dialog-content>
