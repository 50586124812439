import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.css']
})
export class DeactivateAccountComponent implements OnInit {

  constructor(private authService: AuthService, private moreScreenService: MoreScreenService,
    private spinner: NgxSpinnerService, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeactivateAccountComponent>,) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.spinner.show();
    let formData = { uid: this.authService.userValue.uid };
    this.moreScreenService.deactivateAccount(formData).subscribe((res) => {
      this.spinner.hide();
      console.log(res);
      this.openSnackBar(res.message, "");
      if (res.status) this.dialogRef.close("deactivated");
    })
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, { duration: 3000 });
  }
}

