import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Datum } from '../../subscription-model';
import { NavbarService } from 'src/app/services/navbar.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MoreScreenService } from 'src/app/services/more-screen.service';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DowngradeSubscriptionDialogComponent } from '../downgrade-subscription-dialog/downgrade-subscription-dialog.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  uid: string;
  public plansList: Datum[] = [];
  public isVisible: boolean = false;
  selectedCard: string = 'premium'; // Default selection



  selectedItems: number[] = [];
  enquiryForm: FormGroup;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private nav: NavbarService,
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private moreScreenService: MoreScreenService,
    public apiEndPointService: ApiEndpointsService,
    private fb: FormBuilder
  ) {}

  toggleItem(index: number) {
    const selectedIndex = this.selectedItems.indexOf(index);
    if (selectedIndex === -1) {
      this.selectedItems.push(index);
    } else {
      this.selectedItems.splice(selectedIndex, 1);
    }
  }

  isSelected(index: number): boolean {
    return this.selectedItems.includes(index);
  }

  selectCard(cardType: string) {
    this.selectedCard = cardType;
    console.log(cardType);
  }

  isCardSelected(cardType: string): boolean {
    return this.selectedCard === cardType;
  }

  ngOnInit(): void {
    this.enquiryForm = this.fb.group({
      uid: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      organization: new FormControl('', Validators.required),
      designation: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });

    this.authService.isSpecialComponentActive = false;
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.cf.toLowerCase() === 's') {
        // Goes inside this condition if the screen path contains networks as 'n' for 'cf' value
        this.authService.isLoggedIn.next({
          showNavbar: true,
          showSideNav: false,
          cf: params.cf.toLowerCase(),
        });
      }
    });
    this.getPlans();
  }

  async getPlans() {
    let queryParameters = {
      uid:
        localStorage.getItem('uid') ??
        this.uid ??
        this.authService.userValue.uid,
    };
    this.spinner.show();
    (await this.moreScreenService.getPlansList(queryParameters)).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.openSnackBar(res.message, '');
        let response: any;
        if (res.data) {
          let decryptedUserDetails = JSON.parse(
            decodeURIComponent(localStorage.getItem('user'))
          );
          this.apiEndPointService
            .decodeAesToken(this.authService.userValue.uid, res.data)
            .then((data) => {
              response = data;
              console.log('getPlans Response:-');
              console.log(
                JSON.parse(
                  JSON.stringify(
                    JSON.parse(
                      response
                        .replace(/'/g, '"')
                        .replace(/None/g, null)
                        .replace(/False/g, false)
                        .replace(/True/g, true)
                    )
                  )
                )
              );
              this.plansList = JSON.parse(
                JSON.stringify(
                  JSON.parse(
                    response
                      .replace(/'/g, '"')
                      .replace(/None/g, null)
                      .replace(/False/g, false)
                      .replace(/True/g, true)
                  )
                )
              ).data;
            });
        }
      },
      (error) => {
        this.spinner.hide();
        this.openSnackBar('Something went wrong! Please try again later.', '');
        console.log(error);
      }
    );
  }

  onPlanCLick(plan: any) {
    // if (plan.toLowerCase() === 'premium') {
    //   this.router.navigate([
    //     '/subscriptions/subscription-cards',
    //     this.authService.userValue.uid,"PLN12345"
    //   ]);
    // } else if (plan.toLowerCase() === 'free') {
    //   this.router.navigate([
    //     '/subscriptions/subscription-history',
    //     this.authService.userValue.uid,
    //   ]);
    // }

    if (plan.name.toLowerCase() === 'premium') {
      if (!plan.is_current_plan) this.router.navigate(['/subscriptions/subscription-cards', this.uid ?? this.authService.userValue.uid, plan.plan_id, plan.is_current_plan]);
      else this.router.navigate(['/subscriptions/subscribed-plan', localStorage.getItem('uid') ?? this.uid ??  this.authService.userValue.uid]);
    } else if (plan.name.toLowerCase() === 'free') {
      for (plan of this.plansList) {
        if (plan.is_current_plan === true) {
          const dialogRef = this.dialog.open(
            DowngradeSubscriptionDialogComponent,
            {
              data: { validityDays: plan.subscribed_plan_details.days_left },
              panelClass: 'full-width-dialog',
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              // Handle the result from the dialog
              console.log('Dialog result:', result);
            }
          });
        }
      }
    }
  }

  async onEnquiryClick() {
    let formData = this.enquiryForm.value;
    (formData.uid =
      localStorage.getItem('uid') ??
      this.uid ??
      this.authService.userValue.uid),
      this.spinner.show();
    let payload = {
      uid:
        localStorage.getItem('uid') ??
        this.uid ??
        this.authService.userValue.uid,
      name: this.enquiryForm.get('name').value,
      organization: this.enquiryForm.get('organization').value,
      designation: this.enquiryForm.get('designation').value,
      description: this.enquiryForm.get('description').value,
    };
    (await this.moreScreenService.sendAnSubscriptionEnquiry(payload)).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.data) {
          this.apiEndPointService
            .decodeAesToken(this.authService.userValue.uid, res.data)
            .then((data) => {
              if (data)
                console.log(
                  JSON.parse(
                    data.replace(/'/g, '"').replace(/: None/g, ': "None"')
                  )
                );
            });
        }
        if (res.status) {
          this.openSnackBar(res.message, '');
          //this.dialogRef.close();
        } else {
          this.openSnackBar(res.message, '');
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    // Method to open alert messages
    this._snackBar.open(message, action, { duration: 3000 });
  }
}
