<mat-card class="pb-3 jobs-main-container">
  <!-- Jobs Types tab change functionality section -->
  <div class="post-types-div px-3 pt-3">
    <mat-button-toggle-group (change)="onTabChange($event)">
      <mat-button-toggle
        value="my-jobs"
        [checked]="tabName.toLowerCase() == 'my-jobs'"
        >My Jobs</mat-button-toggle
      >
      <mat-button-toggle
        value="post"
        [checked]="tabName.toLowerCase() == 'post'"
        >Post</mat-button-toggle
      >
      <mat-button-toggle
        value="manage"
        [checked]="tabName.toLowerCase() == 'manage'"
        >Manage jobs</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>

  <div class="p-2">
    <ng-container *ngIf="tabName && tabName.toLowerCase() == 'all-jobs'">
      <h2>Recommended jobs</h2>
      <div class="row">
        <div
          *ngIf="recommendedJobsList.length > 0"
          class="recommended-jobs-list"
          [ngStyle]="{
            width: showJobPreview || showJobApplyPreview ? '30%' : '100%',
            flex: showJobPreview || showJobApplyPreview ? '0 0 30%' : '0 0 100%'
          }"
        >
          <div
            *ngFor="let job of recommendedJobsList"
            class="pr-4 border border-2 rounded"
            (click)="jobPreview('recommendedJobsList----job', job)"
          >
            <mat-card class="container-fluid" style="cursor: pointer">
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex"
                  (click)="jobPreview('recommendedJobsList----job', job)"
                >
                  <!---Group Display Picture--->
                  <img
                    [ngStyle]="{
                      width: showJobPreview ? '30px' : '80px',
                      height: showJobPreview ? '30px' : '80px'
                    }"
                    [src]="
                      job.companyProfilePic
                        ? job.companyProfilePic
                        : defaultImage
                    "
                    class="rounded-circle text-center"
                  />
                  <div class="d-flex flex-column m-1">
                    <!---Group Name--->
                    <h3
                      [ngClass]="
                        showJobPreview
                          ? 'job-title-text-sm'
                          : 'job-title-text-md'
                      "
                    >
                      {{ job.job_tle }}
                    </h3>
                    <div class="d-flex flex-row align-items-center">
                      <mat-icon class="close_icon m-1">location_on</mat-icon>
                      <!---Group Members Count--->
                      <h4
                        style="margin: 0; padding: 0"
                        [ngClass]="
                          showJobPreview
                            ? 'job-companyname-text-sm'
                            : 'job-companyname-text-md'
                        "
                      >
                        {{ job.job_cmpny }}
                      </h4>
                      <!---Group Owner--->
                      <h3
                        class="owner"
                        #ownerElement
                        style="margin: 0 10px; padding: 0px 5px"
                        *ngIf="job.is_owner === 'yes'"
                      >
                        Owner
                      </h3>
                    </div>
                    <!---Group Name--->
                    <h6
                      [ngClass]="
                        showJobPreview
                          ? 'job-location-text-sm'
                          : 'job-location-text-md'
                      "
                    >
                      {{ job.job_loc }}
                    </h6>
                  </div>
                </div>

                <!-- ACTIONS ON THE GROUP TO DO -->
                <div class="align-self-center close_icon">
                  <h5
                    [ngClass]="
                      showJobPreview ? 'job-date-text-sm' : 'job-date-text-md'
                    "
                  >
                    {{ job.cd }} ago
                  </h5>
                  <!-- <mat-icon (click)="saveJob(job)">favorite_border</mat-icon> -->
                  <!-- <mat-icon *ngIf="job.ap_sts == 'saved'" (click)="unsaveJob(job)">favorite</mat-icon>
            <mat-icon *ngIf="job.ap_sts != 'saved'" (click)="saveJob(job)">favorite_border</mat-icon> -->
                  <div class="icon-container">
                    <img
                      src="../../../../../assets/jobs_images/unsave.png"
                      alt="Unsave Icon"
                      class="save-icon"
                      *ngIf="job.ap_sts != 'saved'"
                      (click)="saveJob(job)"
                    />
                    <img
                      src="../../../../../assets/jobs_images/save.png"
                      alt="save Icon"
                      class="save-icon"
                      *ngIf="job.ap_sts == 'saved'"
                      (click)="unsaveJob(job)"
                    />
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div
          class="m-auto text-center pt-2"
          *ngIf="recommendedJobsList.length == 0"
          [ngStyle]="{ width: showJobPreview ? '30%' : '100%' }"
        >
          <h2>No Recommended Jobs Found</h2>
        </div>

        <!-- JOBS PREVIEW SCREEN -->
        <section
          class="border border-1 rounded p-1"
          *ngIf="showJobPreview"
          [ngStyle]="{
            width: showJobPreview ? '70%' : '',
            flex: showJobPreview ? '0 0 70%' : '0 0 0%'
          }"
        >
          <!-- JOBS IMAGE SECTION -->
          <img
            *ngIf="!showJobApplyPreview && !showJobPreview"
            src="../../../../assets/img/banner.png"
            alt="nushift_banner"
            height="500"
            width="300"
            class="rounded-circle position-sticky"
          />

          <!-- JOB PREVIEW SECTION -->
          <div *ngIf="showJobPreview" class="job-preview pb-5 pt-3 px-3">
            <div class="row">
              <h2 class="col-11 job-preview-title">
                {{ jobDetails?.job_tle | uppercase }}
              </h2>
              <mat-icon
                class="col-1 close_icon float-right"
                (click)="showJobPreview = !showJobPreview"
                >close</mat-icon
              >
            </div>

            <!-- <br> -->
            <div class="d-flex flex-start job-company-details">
              <!-- <img [src]="(jobDetails?.companyProfilePic && jobDetails?.companyProfilePic !== 'None') ? jobDetails?.companyProfilePic : '../../../assets/img/nushiftconnect_logo.svg'"
                      alt="" class="rounded-circle border" height="50" width="50"> -->
              <div class="d-flex pl-3">
                <h5 class="job-title">
                  {{ jobDetails?.job_cmpny + "," + jobDetails?.job_loc }}
                </h5>
                <h5 class="pl-2 job-location text-muted">
                  {{ "&nbsp;&nbsp;&nbsp;" + jobDetails?.cd }} ago
                </h5>
              </div>
            </div>

            <div class="job-type d-flex flex-start align-items-center">
              <img
                src="../../assets/img/jobs-icon.svg"
                alt=""
                height="25"
                weight="25"
                class=""
              />
              <div class="job-type-text pl-2 rounded-circle job-title">
                {{ jobDetails?.job_typ }}
              </div>
            </div>

            <!-- Underline -->
            <hr class="hr-grey-line" style="margin: 10px; padding: 0" />

            <div class="hiring-team-info p-2 my-1">
              <div class="pb-3"><strong>Meet the Hiring Team</strong></div>
              <div
                class="d-flex flex-start align-items-center hiring-team-details"
              >
                <img
                  [src]="jobDetails?.hirer_details.ppic"
                  alt=""
                  height="60"
                  weight="50"
                  class="border rounded-circle"
                />
                <div class="hiring-team-name pl-4">
                  <div class="team-name">
                    {{ jobDetails?.hirer_details.name }}
                  </div>
                  <div class="team-designation">
                    {{ jobDetails?.hirer_details.subsplid }} |
                  </div>
                  <!-- <div class="team-company text-muted">
                              {{jobDetails?.job_cmpny}}
                          </div> -->
                </div>
              </div>
            </div>
            <hr class="hr-grey-line" style="margin: 0px 10px; padding: 0" />
            <div class="job-description-section px-3">
              <div class="py-3"><strong>About the Job</strong></div>
              <div class="job-description-text text-justify">
                {{ jobDetails?.job_des }}
              </div>
            </div>
            <div
              *ngIf="jobType !== 'activeJob'"
              class="apply-for-job d-flex align-items-center justify-content-around mt-5"
            >
              <button
                mat-raised-button
                class="w-10 mr-5"
                (click)="saveJob(jobDetails)"
              >
                Save
              </button>
              <button
                mat-raised-button
                class="btn-nushift-blue w-10"
                (click)="applyJob(jobDetails)"
              >
                Apply
              </button>
            </div>
            <!-- <div *ngIf="jobType == 'activeJob'"
                  class="manage-posted-job d-flex align-items-center justify-content-aro
                  
                  
                  und mt-5">
                  <button mat-raised-button class="w-50 mr-5">
                      Close a Job
                  </button>
                  <button mat-raised-button class="btn-nushift-blue w-50" (click)="viewApplicants(jobDetails)">
                      View Applicants
                  </button>
              </div> -->
            <!-- <div class="applicants-list mt-5" *ngIf="applicantsList?.length > 0">
                  <h1><strong>Applicants List</strong></h1>
                  <div class="applicants d-flex align-items-center m-4" *ngFor="let applicant of applicantsList">
                      <img [src]="(applicant.ppic && applicant.ppic !== 'None') ? applicant.ppic : '../../../assets/img/nushiftconnect_logo.svg'"
                          alt="" class="rounded-circle border" height="50" width="50">
                      <div class="applicant-name pl-4">{{applicant?.name}}</div>
                  </div>
              </div> -->
          </div>
        </section>
        <!-- APPLY JOB SCREEN -->

        <section
          class="border border-1 rounded p-1"
          *ngIf="showJobApplyPreview"
          [ngStyle]="{
            width: showJobApplyPreview ? '70%' : '',
            flex: showJobApplyPreview ? '0 0 70%' : '0 0 0%'
          }"
        >
          <div class="container-fluid w-100 application-section">
            <div class="w-100 company-name-header mb-3 mt-1"></div>
            <!-- <mat-icon class="col-1 close_icon float-right" (click)="showJobApplyPreview = !showJobApplyPreview">close</mat-icon> -->

            <div class="row">
              <!-- <h2 class="col-11 job-preview-title">  Apply to {{jobDetails.job_cmpny}}</h2>
            <mat-icon class="col-1 close_icon float-right" (click)="showJobApplyPreview = !showJobApplyPreview">close</mat-icon> -->

              <p class="text-dark col-11 job-preview-title">
                <strong>Apply to</strong>
              </p>
              <!-- <h5 class="col-11 job-preview-title">Apply to {{jobDetails?.job_cmpny + ',' + jobDetails?.job_loc}}</h5> -->
              <mat-icon
                class="col-1 close_icon float-right"
                (click)="showJobApplyPreview = !showJobApplyPreview"
                >close</mat-icon
              >
            </div>

            <p class="text-dark"><strong>Contact info</strong></p>
            <div class="company-logo">
              <img
                [src]="
                  jobDetails.companyProfilePic &&
                  jobDetails.companyProfilePic !== 'None'
                    ? jobDetails.companyProfilePic
                    : '../../../assets/img/nushiftconnect_logo.svg'
                "
                alt=""
                class="rounded-circle border"
                height="100"
                width="100"
              />
            </div>
            <div class="container-fluid apply-job-form row w-100">
              <form
                [formGroup]="applicationForm"
                (ngSubmit)="submitJob(jobDetails)"
              >
                <mat-form-field
                  appearance="standard"
                  floatLabel="always"
                  class="w-100"
                >
                  <mat-label class="label-heading"
                    >Name<span class="text-danger">*</span></mat-label
                  >
                  <input matInput formControlName="name" placeholder="Name" />
                  <div
                    *ngIf="
                      applyJobForm &&
                      applicationForm.get('name').errors?.required &&
                      applicationForm.get('name').touched
                    "
                  >
                    Name is required.
                  </div>
                </mat-form-field>
                <mat-form-field
                  appearance="standard"
                  floatLabel="always"
                  class="w-100"
                >
                  <mat-label class="label-heading"
                    >Phone Number<span class="text-danger">*</span></mat-label
                  >
                  <input
                    matInput
                    formControlName="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <div
                    *ngIf="
                      applyJobForm &&
                      applicationForm.get('phoneNumber').errors?.required &&
                      applicationForm.get('phoneNumber').touched
                    "
                  >
                    Phone Number is required.
                  </div>
                </mat-form-field>
                <mat-form-field
                  appearance="standard"
                  floatLabel="always"
                  class="w-100"
                >
                  <mat-label class="label-heading"
                    >Email Address<span class="text-danger">*</span></mat-label
                  >
                  <input
                    matInput
                    formControlName="emailAddress"
                    placeholder="Email Address"
                  />
                  <div
                    *ngIf="
                      applyJobForm &&
                      applicationForm.get('emailAddress').errors?.required &&
                      applicationForm.get('emailAddress').touched
                    "
                  >
                    Email is required.
                  </div>
                </mat-form-field>
                <mat-label class="label-heading"
                  >upload Resume<span class="text-danger">*</span></mat-label
                >
                <br />
                <div
                  class="d-flex justify-content-center align-items-center mt-3"
                >
                  <!-- <button mat-raised-button class="w-50 mr-5" (click)="fileInput.click()"> -->
                  <div class="border rounded w-100" (click)="fileInput.click()">
                    Upload Resume
                  </div>
                  <!-- </button> -->
                  <input
                    hidden
                    type="file"
                    accept="text/plain,.txt,.doc,.docx,.pdf,application/pdf"
                    placeholder="Drag and drop"
                    #fileInput
                    (change)="uploadResume($event.target.files)"
                  />
                  <p class="pl-3">{{ uploadedResume?.name }}</p>
                </div>
                <!-- <input hidden type="file" accept="application/pdf" placeholder="Drag and drop" #fileInput
                  (change)="uploadResume($event.target.files)"/> -->
                <div
                  class="submit-job-application d-flex align-items-center justify-content-around mt-5"
                >
                  <!-- <button mat-raised-button class="w-50 mr-5" (click)="closeDialog()">
                      Cancel
                  </button> -->
                  <button
                    mat-raised-button
                    class="btn-nushift-blue w-50"
                    type="submit"
                  >
                    Submit
                  </button>
                  <div
                    *ngIf="applyJobForm && applicationForm.invalid"
                    class="error-message"
                  >
                    Please fill out all fields correctly.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="tabName && tabName.toLowerCase() == 'my-jobs'">
      <app-my-manage-jobs [tabName]="tabName"></app-my-manage-jobs>
    </ng-container>
    <ng-container *ngIf="tabName && tabName.toLowerCase() == 'post'">
      <!-- <app-create-job [tabName]="tabName"></app-create-job> -->

      <div class="container-fluid w-100 create-group-main-container">
        <div class="mx-auto p-2" style="width: 140px; height: 140px">
          <img
            src="../../.././../../assets/jobs_images/create_job.png"
            alt="create_job"
          />
        </div>
        <p class="mt-5">Tell us who you are hiring</p>

        <div class="container-fluid create-job-form row w-100">

          <form [formGroup]="createJobForm" (ngSubmit)="createJob()">

    
            
            <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
              <!-- <mat-label class="label-heading">
                Job Title <span class="text-danger">*</span>
              </mat-label> -->
              <mat-label style="color: black;"> <strong>  Job Title </strong><span class="text-danger">*</span> </mat-label>

              <input
                matInput
                formControlName="job_title"
                placeholder="Enter your Job Title"
                maxlength="100"
              />
              <!-- <div
                *ngIf="
                  submittedJobForm &&
                  createJobForm.get('job_title').errors?.required &&
                  createJobForm.get('job_title').touched
                "
              >
                Job Title is required.
              </div> -->
              <!-- <mat-hint>Should not exceed 100 characters</mat-hint> -->
            </mat-form-field>

            <!-- Company -->
            <div class="company w-90">
              <mat-form-field appearance="outline" floatLabel='always' class=" w-100">

                <!-- <mat-label
                  >Company <span class="text-danger">*</span></mat-label
                > -->

                <mat-label style="color: black;"> <strong> Company </strong><span class="text-danger">*</span> </mat-label>

                <mat-select
                  class="company-dropdown"
                  formControlName="organization_name"
                  placeholder="Select your Company"
                >
                  <mat-option
                    [value]="organization_name"
                    *ngFor="
                      let organization_name of companiesList.organization_name
                    "
                    >{{ organization_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <div
                *ngIf="
                  submittedJobForm &&
                  createJobForm.get('organization_name').errors?.required &&
                  createJobForm.get('organization_name').touched
                "
              >
                organization_name is required.
              </div> -->
            </div>

            <mat-form-field appearance="outline" floatLabel='always' class=" w-100">

              <!-- <mat-label class="label-heading">
                Job Location <span class="text-danger">*</span>
              </mat-label> -->

              <mat-label style="color: black;"> <strong> Job Location </strong><span class="text-danger">*</span> </mat-label>

              <input
                matInput
                formControlName="job_location"
                placeholder="Enter your job location"
                maxlength="100"
              />
              <!-- <mat-hint>Should not exceed 100 characters</mat-hint> -->
              <!-- <div
                *ngIf="
                  submittedJobForm &&
                  createJobForm.get('job_location').errors?.required &&
                  createJobForm.get('job_location').touched
                "
              >
                Job Location is required.
              </div> -->
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
              <!-- <mat-label class="label-heading">
                Job Type <span class="text-danger">*</span>
              </mat-label> -->
              <mat-label style="color: black;"> <strong> Job Type </strong><span class="text-danger">*</span> </mat-label>

              <input
                matInput
                formControlName="job_type"
                placeholder="Enter your Job Type"
                maxlength="100"
              />
              <!-- <mat-hint>Should not exceed 100 characters</mat-hint> -->
              <!-- <div
                *ngIf="
                  submittedJobForm &&
                  createJobForm.get('job_type').errors?.required &&
                  createJobForm.get('job_type').touched
                "
              >
                Job Type is required.
              </div> -->
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel='always' class=" w-100">
              <!-- <mat-label class="label-heading">
                Job Description <span class="text-danger">*</span>
              </mat-label> -->
              <mat-label style="color: black;"> <strong> Job Description </strong><span class="text-danger">*</span> </mat-label>

              <input
                matInput
                formControlName="job_description"
                placeholder="Enter your Job description"
                maxlength="100"
              />
              <!-- <mat-hint>Should not exceed 100 characters</mat-hint> -->
              <!-- <div
                *ngIf="
                  submittedJobForm &&
                  createJobForm.get('job_description').errors?.required &&
                  createJobForm.get('job_description').touched
                "
              >
                job_description is required.
              </div> -->
            </mat-form-field>
            <mat-checkbox
              [checked]="isChecked"
              (change)="checkboxChanged($event)"
              >Ananymously post this job</mat-checkbox
            >
            <div class="mx-auto p-2">
              <button class="btn btn-nushift-blue text-center">Create</button>
            </div>
            <div
              *ngIf="submittedJobForm && createJobForm.invalid"
              class="error-message"
            >
              Please fill out all fields correctly.
            </div>
          </form>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tabName && tabName.toLowerCase() == 'manage'">
      <h2>Manage jobs</h2>
      <app-my-manage-jobs></app-my-manage-jobs>

      <!-- <div
        class="w-20 m-auto text-center pt-5"
        *ngIf="requestedGroupsList.length == 0"
      >
        <h2>No Manage Jobs Found</h2>
      </div> -->
    </ng-container>
  </div>
</mat-card>
<!-- SHOWING LOADER ON THE WEBSITE -->
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
  <b style="color: white"> Loading... </b>
</ngx-spinner>
