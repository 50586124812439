import { Component, OnInit,  Renderer2, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { AuthService } from 'src/app/services/auth.service';
import * as pluginLabels from 'chartjs-plugin-labels';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../dashboard.service';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {



  applicantsList: any;


  // Referrals Count
  referralSentCount: string = '';
  referralRecievedCount: string = '';
  referralPendingCount: string = '';

  // Followers Data

  totalFollowersCount: string = '';
  followersData: any;
  totalFeedCount: string = '';
  feedLikesData: any;
  feedSharesData: any;
  feedCommentsData: any;
  feedbackData: any;

// Connection
   connectionsData: any;
   connectionsValues: any;




  followersChartLabels: string[] = [];
  public followersChartData: Chart.ChartDataSets[] = [
    {
      data: [],
      label: 'followers',
      fill: false  // Ensures the line is not filled
    }
  ];
  public followersChartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public followersChartLegend = true;
  public followersLineChartType = 'line';
  public followersChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: '#002469',
      borderWidth: 2,
    }
  ];



  feedChartLabels: string[] = [];
  public feedChartData: Chart.ChartDataSets[] = [
    {
      data: [],
      label: 'feed',
      fill: false  // Ensures the line is not filled
    }
  ];
  public feedChartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public feedChartLegend = true;
  public feedLineChartType = 'line';
  public feedChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'red',
      borderWidth: 2,
    }
  ];

  feedbackButtons = [
    {
      id: 'yet_to_receive',
      selected: true,
      color: '#E32020',
      defaultImage: '../../../../assets/dashboard_images/red_fill.png',
      selectedImage: '../../../../assets/dashboard_images/red_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/red_outer.png'
    },
    {
      id: 'sent',
      selected: false,
      color: '#FBDF4A',
      defaultImage: '../../../../assets/dashboard_images/yellow_outer.png',
      selectedImage: '../../../../assets/dashboard_images/yellow_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/yellow_outer.png'
    },
    {
      id: 'received',
      selected: false,
      color: '#71EE76',
      defaultImage: '../../../../assets/dashboard_images/green_outer.png',
      selectedImage: '../../../../assets/dashboard_images/green_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/green_outer.png'
    },
    {
      id: 'pending',
      selected: false,
      color: '#3F8CD2',
      defaultImage: '../../../../assets/dashboard_images/blue_outer.png',
      selectedImage: '../../../../assets/dashboard_images/blue_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/blue_outer.png'
    }
  ];

  buttons = [
    {
      id: 'total',
      selected: false,
      color: '#E32020',
      defaultImage: '../../../../assets/dashboard_images/totalcount.png',
      selectedImage: '../../../../assets/dashboard_images/red_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/red_outer.png'

    },
    {
      id: 'share',
      selected: false,
      color: '#FBDF4A',
      defaultImage: '../../../../assets/dashboard_images/share.png',
      selectedImage: '../../../../assets/dashboard_images/yellow_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/yellow_outer.png'

    },
    {
      id: 'comment',
      selected: false,
      color: '#71EE76',
      defaultImage: '../../../../assets/dashboard_images/comments.png',
      selectedImage: '../../../../assets/dashboard_images/green_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/green_outer.png'

    },
    {
      id: 'like',
      selected: false,
      color: '#3F8CD2',
      defaultImage: '../../../../assets/dashboard_images/like.png',
      selectedImage: '../../../../assets/dashboard_images/blue_fill.png',
      unselectedImage: '../../../../assets/dashboard_images/blue_outer.png'

    }
  ];



  connectionsChartLabels: string[] = ['Doctors', 'Diagnostics', 'Hospitals'];
  public connectionsChartData: Chart.ChartDataSets[] = [
    {
      data: [2, 1, 11],
      label: 'Connections',
      fill: true,
      barPercentage: 0.1, // Adjust the width of each bar
      categoryPercentage: 0.5 // Adjust the space between bars
       // Ensures the line is not filled
    },


  ];
  public connectionsChartOptions: Chart.ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontColor: 'black'
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end', 
      }
    }
  };
  public connectionsChartLegend = true;
  public connectionsLineChartType = 'bar';
  public connectionsChartColors: Array<any> = [
    {
      backgroundColor: this.getConnectionColors(),
      // borderColor: 'blue',
      borderWidth: 1,
    }
  ];


  feedbackChartLabels: string[] = [];
  public feedbackChartData: Chart.ChartDataSets[] = [
    {
      data: [],
      label: 'Referrals Feedback',
      fill: false  // Ensures the line is not filled
    }
  ];
  public feedbackChartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public feedbackChartLegend = true;
  public feedbackLineChartType = 'line';
  public feedbackChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: '#002469',
      borderWidth: 2,
    }
  ];


  isMobile: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private apiEndPointService: ApiEndpointsService,
    private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) { 
  }

  ngOnInit(): void {
    this.authService.isSpecialComponentActive = false;
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
        console.log('Is mobile:', this.isMobile);

        this.activatedRoute.queryParams.subscribe((params: any) => {
          if (params.cf.toLowerCase() === 'd') {
            // Goes inside this condition if the screen path contains dashboard as 'n' for 'cf' value
            this.authService.isLoggedIn.next({
              showNavbar: this.isMobile ? false : true,
              showSideNav: true,
              cf: params.cf.toLowerCase(),
            });
          }
        });
        
      });




   this.getNewAnalytics('year');

  }


  
  getConnectionColors(): string[] {
    // Define your custom colors for each month
    return [
      '#3F8CD2',  // January
      '#FBDF4A',   // February
      '#71EE76',   // March
    ];
  }

  // feedAction(actionType:string,colorValue:string){
  //   this.feedChartLabels =  this.applicantsList['axis'];
  //   this.feedChartData = [
  //     {
  //       data: this.applicantsList['engagement'][actionType],
  //       label: actionType.toLocaleUpperCase(),
  //       fill: false  // Ensures the line is not filled
  //     }
  //   ];  
  //   this.feedChartColors = [
  //     {
  //       backgroundColor: 'rgba(105, 0, 132, .2)',
  //       borderColor: colorValue,
  //       borderWidth: 2,
  //     }
  //   ];
  // }


  feedAction(buttonId: string) {
    // Deselect all buttons
    this.buttons.forEach(button => button.selected = false);
    // Select the clicked button
    const button = this.buttons.find(button => button.id === buttonId);
    this.feedChartLabels =  this.applicantsList['axis'];
      this.feedChartData = [
        {
          data: this.applicantsList['engagement'][buttonId],
          label: buttonId.toLocaleUpperCase(),
          fill: false  // Ensures the line is not filled
        }
      ];  
      this.feedChartColors = [
        {
          backgroundColor: 'rgba(105, 0, 132, .2)',
          borderColor: button.color,
          borderWidth: 2,
        }
      ];

    if (button) {
      button.selected = true;
    }
  }


  referralfeedbackAction(buttonId : string){


 // Deselect all buttons
 this.feedbackButtons.forEach(button => button.selected = false);
 // Select the clicked button
 const button = this.feedbackButtons.find(button => button.id === buttonId);
 if (button) {
   button.selected = true;
 }


      this.feedbackChartLabels =  this.applicantsList['axis'];
      this.feedbackChartData = [
        {
          data: this.applicantsList['feedback'][buttonId],
          label: buttonId.toLocaleUpperCase(),
          fill: false  // Ensures the line is not filled
        }
      ];  
      this.feedbackChartColors = [
        {
          backgroundColor: 'rgba(105, 0, 132, .2)',
          borderColor: button.color,
          borderWidth: 2,
        }
      ];
 
  }


  async getNewAnalytics(filterType : string) {
    let payload = {
      uid: this.authService.userValue.uid,
      filter_by: filterType,
      // start_date: '2023-05-20',
      // end_date: '2024-05-20',
      // filter_value: 2024,
    }
    console.log(payload);
    this.spinner.show('yyyyyyyyyyyyyyy');
    (await this.dashboardService.getNewAnalytics(payload)).subscribe((res: any) => {
  
      let response;
      if (res.status) {
        console.log(res);
        this.apiEndPointService.decodeAesToken(this.authService.userValue.uid, res.data).then((data) => {
          response = data;
          this.applicantsList = JSON.parse(response.replace(/'/g, '"').replace(/: None/g, ': "None"'));
          // console.log(this.applicantsList['referrals']['sent'],'Referrals Data');
          this.referralSentCount = this.applicantsList['referrals']['sent'];
          this.referralPendingCount = this.applicantsList['referrals']['pending'];
          this.referralRecievedCount = this.applicantsList['referrals']['received'];
          this.totalFollowersCount =  this.applicantsList['followers']['total'];
          this.followersData =  this.applicantsList['followers']['followers_data'];
          this.followersChartLabels =  this.applicantsList['axis'];
          this.connectionsData =  this.applicantsList['connections'];
          console.log( this.applicantsList['connections'],'connections Data');


          // console.log(this.applicantsList['axis'],'followersData');
          this.followersChartData = [
            {
              data: this.followersData,
              label: 'followers',
              fill: false  // Ensures the line is not filled
            }
          ];

        
        this.feedAction('total');
        this.referralfeedbackAction('yet_to_receive');
        this.connectionsChartLabels = Object.keys(this.connectionsData);
        this.connectionsValues =  Object.values(this.connectionsData);
        this.connectionsChartData = [
          {
            data:  this.connectionsValues,
            label: 'Connections',
            fill: true,
            barPercentage: 0.1, // Adjust the width of each bar
            categoryPercentage: 0.5 // Adjust the space between bars
             // Ensures the line is not filled
          },
        ];
        

        });
        this.spinner.hide();
        this.openSnackBar(res.message, '');
      }

    }, error => {
      this.spinner.hide();
      this.openSnackBar(error, '');
    });
  }


  openSnackBar(message: string, action: string): void {
    // Method to show alert when any api calls
    this._snackBar.open(message, action, { duration: 3000 });
  }

}