import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit {
  about: any[];
  constructor(
    private moreScreenService: MoreScreenService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getAbout();
  }

  getAbout() {
    this.moreScreenService.aboutUs().subscribe((res) => {
      console.log(res);
      if (res.status) {
        this.about = res.data;
      } else {
        this.openSnackBar(res.message, '');
      }
    });
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, { duration: 3000 });
  }
}
 