import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { MoreScreenService } from 'src/app/services/more-screen.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  user: User;
  contactUsForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    description: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
  });
  decryptedUserDetails: any;

  constructor(private moreScreenService: MoreScreenService, private _snackBar: MatSnackBar, private spinner: NgxSpinnerService,
    private authService: AuthService, public dialogRef: MatDialogRef<ContactUsComponent>, private endPointService: ApiEndpointsService) { }

  ngOnInit(): void {
    this.decryptedUserDetails = JSON.parse(decodeURIComponent(localStorage.getItem('user')));
    this.user = this.authService.userValue;
    this.contactUsForm.patchValue({
      name: this.user.name,
      email: this.user.email,
      phone: this.user.mob
    });

  }

  async onSubmit() {
    this.spinner.show();
    let formData = this.contactUsForm.value;
    formData['uid'] = this.authService.userValue.uid;
    (await this.moreScreenService.contactUs(formData)).subscribe((res) => {
      this.spinner.hide();
      if (res.status) {
        this.openSnackBar(res.message, "");
        if (res.data) {
          let response: any;
          this.endPointService.decodeAesToken(this.authService.userValue.uid ?? this.decryptedUserDetails.uid, res.data).then((data) => {
            response = data;
            console.log('contactUs Response:-');
            console.log(JSON.parse(JSON.stringify(JSON.parse(response.replace(/'/g, '"').replace(/None/g, null).replace(/False/g, false).replace(/True/g, true)))));
            this.dialogRef.close();
          });
        } else {
          this.dialogRef.close();
        }
      } else {
        console.log(res);
      }
    }, error => {
      this.spinner.hide();
      console.log(error);
    })
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, { duration: 3000 });
  }
}
