import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-downgrade-subscription-dialog',
  templateUrl: './downgrade-subscription-dialog.component.html',
  styleUrls: ['./downgrade-subscription-dialog.component.scss']
})
export class DowngradeSubscriptionDialogComponent implements OnInit {

  validityDays: string = '';

  constructor(
    public dialogRef: MatDialogRef<DowngradeSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.validityDays) {
      this.validityDays = this.data.validityDays;
    }

    console.log(this.validityDays);
  }

  onClose(result: any): void {
    this.dialogRef.close(result);
  }
  ngOnInit(): void {}

}
