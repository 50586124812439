import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MoreScreenService } from 'src/app/services/more-screen.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  about: any;

  constructor(public nav: NavbarService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private settingsService: MoreScreenService,
    public dialog: MatDialog) { }

    ngOnInit(): void {
      this.nav.navbarHide();
      this.nav.footerShow();
      this.nav.setfooterNotFixed();
      this.getAbout();
    }
    getAbout() {
      this.settingsService.aboutUs().subscribe((res) => {
        console.log(res);
        if (res.status) {
          this.about = res.data;
        } else {
          this.openSnackBar(res.message, '');
        }
      });
    }
    openSnackBar(message: string, action: string): void {
      this._snackBar.open(message, action, { duration: 3000 });
    }

}
