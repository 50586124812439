import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { environment } from './models/constants';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { JobsComponent } from './modules/jobs/components/jobs/jobs.component';
import { MyManageJobsComponent } from './modules/jobs/components/my-manage-jobs/my-manage-jobs.component';
import { ApplyJobComponent } from './modules/jobs/components/apply-job/apply-job.component';
import { CommonModule } from '@angular/common';
import { MainDashboardComponent } from './modules/dashboard/main-dashboard/main-dashboard.component';
import { AuthGuard } from './modules/auth.guard';
import { MatMenuModule } from '@angular/material/menu';
import { EnquiriesComponent } from './components/enquiries/enquiries.component';
import { RouterModule } from '@angular/router';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { RateUsComponent } from './components/rate-us/rate-us.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DeactivateAccountComponent } from './components/deactivate-account/deactivate-account.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { RegistrationComponent } from './modules/register/registration/registration.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { AboutComponent } from './components/about/about.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { GetInvitedComponent } from './modules/get-invited/get-invited/get-invited.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingScreenComponent,
    LoginComponent,
    NotificationsComponent,
    JobsComponent,
    MyManageJobsComponent,
    ApplyJobComponent,
    MainDashboardComponent,
    EnquiriesComponent,
    TermsConditionsComponent,
    RateUsComponent,
    AboutUsComponent,
    ChangePasswordComponent,
    DeactivateAccountComponent,
    ContactUsComponent,
    DeleteAccountComponent,
    RegistrationComponent,
    PrivacyComponent,
    TermsComponent,
    AboutComponent,
    ForgotPasswordComponent,
    GetInvitedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.firebase),
    CommonModule,
    RouterModule,
    MatFormFieldModule
  ],
  providers: [AuthGuard], // Provide the AuthGuard
  // providers: [], // Provide the AuthGuard

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
